import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { IoFilterSharp } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import OverallChallan from "./Graphs/OverallChallan";
import PoliceChallanPie from "./Graphs/PoliceChallanpie";
import PoliceChallan from "./Graphs/PoliceChallan";
import InspectionGraph from "./Graphs/InspectionGraph";
import CitizenChallan from "./Graphs/CitizenChallan";
import BarChart from "./Graphs/Region";
const dummyData = {
  officers: [
    {
      id: 1,
      officerId: "2634628",
      totalInspections: 50,
      challan: 30000,
      areaName: "Lala nagar",
      challanCreation: 20,
    },
    {
      id: 2,
      officerId: "2634628",
      totalInspections: 45,
      challan: 28000,
      areaName: "Lala nagar",
      challanCreation: 18,
    },
    {
      id: 3,
      officerId: "2634628",
      totalInspections: 44,
      challan: 26000,
      areaName: "Lala nagar",
      challanCreation: 16,
    },
    {
      id: 4,
      officerId: "7777777",
      totalInspections: 40,
      challan: 25000,
      areaName: "Lala nagar",
      challanCreation: 15,
    },
    {
      id: 5,
      officerId: "2634628",
      totalInspections: 38,
      challan: 20000,
      areaName: "Lala nagar",
      challanCreation: 12,
    },
  ],
  citizens: [
    {
      id: 1,
      mobileNo: "2634628",
      totalReports: 50,
      challan: 30000,
      areaName: "Lala nagar",
    },
    {
      id: 2,
      mobileNo: "2634628",
      totalReports: 45,
      challan: 28000,
      areaName: "Lala nagar",
    },
    {
      id: 3,
      mobileNo: "2634628",
      totalReports: 44,
      challan: 26000,
      areaName: "Lala nagar",
    },
    {
      id: 4,
      mobileNo: "7777777",
      totalReports: 40,
      challan: 25000,
      areaName: "Lala nagar",
    },
    {
      id: 5,
      mobileNo: "2634628",
      totalReports: 38,
      challan: 20000,
      areaName: "Lala nagar",
    },
  ],
};

const TopPerformers = () => {
  const [activeTab, setActiveTab] = useState("officers");
  const [filterType, setFilterType] = useState("challanCollection");
  const [regionFilter, setRegionFilter] = useState("");
  const [dayFilter, setDayFilter] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Simulate API call
    setTimeout(() => {
      let filteredData = dummyData[activeTab];

      if (regionFilter) {
        filteredData = filteredData.filter(
          (item) => item.areaName === regionFilter
        );
      }

      if (dayFilter) {
        // Assuming each item has a date property for filtering
        filteredData = filteredData.filter((item) => item.date === dayFilter);
      }

      if (filterType === "challanCollection") {
        filteredData = filteredData.sort((a, b) => b.challan - a.challan);
      } else if (filterType === "challanCreation") {
        filteredData = filteredData.sort(
          (a, b) => b.challanCreation - a.challanCreation
        );
      } else if (filterType === "inspections") {
        filteredData = filteredData.sort(
          (a, b) => b.totalInspections - a.totalInspections
        );
      }

      setData(filteredData);
    }, 500);
  }, [activeTab, filterType, regionFilter, dayFilter]);

  const getTableHeaderLabel = () => {
    if (activeTab === "officers") {
      if (filterType === "challanCollection") {
        return "Challan Collection";
      } else if (filterType === "challanCreation") {
        return "Challan Creation";
      } else {
        return "Total Inspections";
      }
    } else {
      return "Total Reports"; // For citizens
    }
  };

  const renderGraph = () => {
    if (activeTab === "officers") {
      if (filterType === "challanCollection") {
        return <PoliceChallan />;
      } else if (filterType === "challanCreation") {
        return <PoliceChallanPie />;
      } else if (filterType === "inspections") {
        return <InspectionGraph />;
      }
    } else {
      return <CitizenChallan />;
    }
    // Add more conditions here if there are graphs for the citizens tab
    return null;
  };

  return (
    <>
      <Navbar />
      <div className="lg:px-20 px-2 lg:mt-[5%] mt-[15%]">
        <h1 className="text-3xl lg:text-4xl font-bold mb-4 text-[#0d1466]">
          Commissioner Home
        </h1>
        <div className="relative flex items-center gap-2 justify-start w-20 rounded-md p-2 mb-6 shadow-md lg:mx-12">
          
          <button className="flex gap-2 items-center" onClick={() => navigate(-1)}><IoArrowBack className=" text-gray-500 pointer-events-none" />Back</button>
        </div>
        <h1 className="text-2xl lg:text-3xl font-bold mb-4 text-[#0d1466] px-6 lg:px-12 mt-8">
          Top Performers{" "}
          <select
            name="regionFilter"
            className="ml-3 mt-1 p-2 text-black text-sm bg-transparent rounded-md focus:outline-none focus:ring-0 focus:ring-[#000]"
            onChange={(e) => setRegionFilter(e.target.value)}
          >
            <option value="">All Regions</option>
            <option value="State">State</option>
            <option value="District">District</option>
            <option value="Zone">Zone</option>
            <option value="Station">Station</option>
          </select>
        </h1>
        <BarChart />
        <div className="flex flex-wrap justify-between space-y-8 lg:space-y-0 px-6 lg:px-16 lg:gap-x-1 mt-12 mb-12 lg:w-full">
          <button
            className={` transition  duration-500 hover:-translate-y-1 hover:scale-105 py-6 px-1 lg:w-[20%] w-full h-24 rounded-lg font-semibold shadow-xl ${
              activeTab === "officers" && filterType === "challanCollection"
                ? "bg-[#445cf6] text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveTab("officers");
              setFilterType("challanCollection");
            }}
          >
            Top Performing Officers by challan collection
          </button>
          <button
            className={`transition  duration-500 hover:-translate-y-1 hover:scale-105 py-6 px-2 lg:w-[20%] w-full h-24 rounded-lg font-semibold shadow-2xl ${
              activeTab === "officers" && filterType === "challanCreation"
                ? "bg-[#445cf6] text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveTab("officers");
              setFilterType("challanCreation");
            }}
          >
            Top Performing Officers by challan creation
          </button>
          <button
            className={`transition  duration-500 hover:-translate-y-1 hover:scale-105 py-6 px-2 lg:w-[20%] w-full h-24 rounded-lg font-bold shadow-2xl ${
              activeTab === "officers" && filterType === "inspections"
                ? "bg-[#445cf6] text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveTab("officers");
              setFilterType("inspections");
            }}
          >
            Top Performing Officers by Inspections
          </button>
          <button
            className={`transition  duration-500 hover:-translate-y-1 hover:scale-105 py-6 px-2 lg:w-[20%] w-full h-24 rounded-lg font-bold shadow-2xl ${
              activeTab === "citizens"
                ? "bg-[#445cf6] text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveTab("citizens");
              setFilterType("");
            }}
          >
            Top Performing Citizens
          </button>
        </div>
        <div>
          <div className="relative flex justify-end mb-6 lg:w-[900px] lg:mx-24">
            <select
              name="dayFilter"
              className="ml-3 mt-1 shadow-lg p-2 text-base bg-transparent border appearance-none rounded-md focus:outline-none focus:ring-0 focus:ring-black pr-10"
              onChange={(e) => setDayFilter(e.target.value)}
            >
              <option value="">All Days</option>
              <option value="2024-07-14">Today</option>
              <option value="2024-07-13">Yesterday</option>
              <option value="2024-07-12">13/07/2024</option>
              <option value="2024-07-11">12/07/2024</option>
            </select>
            <IoFilterSharp className="absolute top-1/2 transform -translate-y-1/2 right-3 text-gray-500 pointer-events-none" />
          </div>
        </div>
        {renderGraph()}
        <div className="overflow-x-auto border-2 lg:mx-24 lg:w-[900px] mb-12 rounded-xl">
          <table className="w-full border-collapse border border-gray-200">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 text-xs lg:text-base">S.NO</th>
                <th className="p-2 text-xs lg:text-base">
                  {activeTab === "officers" ? "Officer Id" : "Mobile NO"}
                </th>
                <th className="p-2 text-xs lg:text-base">
                  {getTableHeaderLabel()}
                </th>
                <th className="p-2 text-xs lg:text-base">Challan</th>
                <th className="p-2 text-xs lg:text-base">Area Name</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id}>
                  <td className="p-2 text-sm lg:text-base">{index + 1}</td>
                  <td className="p-2 text-sm lg:text-base">
                    {activeTab === "officers" ? item.officerId : item.mobileNo}
                  </td>
                  <td className="p-2 text-sm lg:text-base">
                    {activeTab === "citizens"
                      ? item.totalReports
                      : filterType === "challanCollection"
                      ? item.challan
                      : filterType === "challanCreation"
                      ? item.challanCreation
                      : item.totalInspections}
                  </td>
                  <td className="p-2 text-sm lg:text-base">{item.challan}</td>
                  <td className="p-2 text-sm lg:text-base">{item.areaName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TopPerformers;
