import React, { useEffect, useState } from 'react';
import '../css/Points.css';
import Navbar from './Navbar';
import axios from 'axios';
import Sidebar from './Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { RiCloseCircleLine } from "react-icons/ri";

const Points = () => {
  const [violationData, setViolationData] = useState([]);
  const [trafficData, setTrafficData] = useState([]);
  const [accidentData, setAccidentData] = useState([]);
 
    //Image Modal
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
  
    const openModal = (imageSrc) => {
      setModalImage(imageSrc);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setModalImage("");
    };
  
    const Modal = ({ imageSrc, onClose }) => (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="relative">
          <img
            src={imageSrc}
            alt="Enlarged Violation"
            className="max-w-screen max-h-screen rounded-xl"
          />
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-2 text-white   bg-black bg-opacity-50 px-2 py-1 rounded-lg"
          >
            <RiCloseCircleLine className="text-3xl"/>
          </button>
        </div>
      </div>
    );
   
  const navigate = useNavigate();

  async function fetchData() {
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/common/get-reports', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        });
        if(!response){
          toast.error("No response from server. Please try again later.")
          return;
        }
        if (response.status === 200) {
          const data = response.data;
    
          const violationHistory = data.violations;
          const trafficHistory = data.trafficjams;
    
          const accidentHistory = data.accidents.map(accident => ({
            ...accident,
            status: accident.status || 'unknown' // Set default status if not provided
          }));
    
          setViolationData(violationHistory);
          setTrafficData(trafficHistory);
          setAccidentData(accidentHistory);
    
          console.log(data);
          console.log(violationHistory);
          console.log(trafficHistory);
          console.log(accidentHistory);
        }
      }catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session has been expired. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [navigate]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="point-main">
        <div className="content">
          <div className="point-title">
            <h1 className="color po-title">Your Reports</h1>
          </div>
          <div className="point-tables">
            <div className="violation-table">
              <h3 className="color po-tt">Violation Reports</h3>
              <div className="table-points">
                {violationData.length === 0 ? (
                  <div className="no-reports-message">
                    You have zero violation reports.
                  </div>
                ) : (
                  <table className='pointsTable'>
                    <thead className="pointsThead">
                      <tr className="t-head">
                        <th>S.no</th>
                        <th>Date</th>
                        <th>Vehicle Number</th>
                        <th>Violation</th>
                        <th>Status</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {violationData.map((row, index) => (
                        <tr className="t-data" key={index}>
                          <td>{index + 1}</td>
                          <td>{row.violation_date}</td>
                          <td>{row.vehicle_number}</td>
                          <td>{row.violation_type}</td>
                          <td>{row.status}</td>
                          <td className='t-data-img'>
                          <img src={row.violation_img_url || "-"} alt="-" height={200} onClick={() => openModal(row.violation_img_url)}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="traffic-table">
              <h3 className="color po-tt">Traffic Jam Reports</h3>
              <div className="table-points">
                {trafficData.length === 0 ? (
                  <div className="no-reports-message">
                    You have zero traffic jam reports.
                  </div>
                ) : (
                  <table className='pointsTable'>
                    <thead className="pointsThead">
                      <tr className="t-head">
                        <th>S.no</th>
                        <th>Date</th>
                        <th>Area Name</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trafficData.map((row, index) => (
                        <tr className="t-data" key={index}>
                          <td>{index + 1}</td>
                          <td className='t-data-tt'>{row.jam_date}</td>
                          <td>{row.jam_areaname}</td>
                          <td>{row.jam_address.split(',')[0]}</td>
                          <td>{row.status}</td>
                          <td className='t-data-img'>
                          <img src={row.trafficjam_img_url || "-"} alt="-" height={200} onClick={() => openModal(row.trafficjam_img_url)}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="accident-table">
              <h3 className="color po-tt">Accident Reports</h3>
              <div className="table-points">
                {accidentData.length === 0 ? (
                  <div className="no-reports-message">
                    You have zero accident reports.
                  </div>
                ) : (
                  <table className='pointsTable'>
                    <thead className="pointsThead">
                      <tr className="t-head">
                        <th>S.no</th>
                        <th>Date</th>
                        <th>Area Name</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accidentData.map((row, index) => (
                        <tr className="t-data" key={index}>
                          <td>{index + 1}</td>
                          <td>{row.accident_date}</td>
                          <td>{row.area_name}</td>
                          <td>{row.accident_address.split(',')[0]}</td>
                          <td>{row.status}</td>
                          <td className='t-data-img'>
                          <img src={row.accident_img_url || "-"} alt="-" height={200} onClick={() => openModal(row.accident_img_url)}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <Modal imageSrc={modalImage} onClose={closeModal} />}
      <ToastContainer position='top-right' />
    </>
  );
};

export default Points;
