import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import imageIcon from "../../assets/Image.png";
// import { Link } from "react-router-dom";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';


function AddVehicle1() {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [detailsConfirmed, setDetailsConfirmed] = useState(false);
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);
  const [frontimg, setFrontimg] = useState(null);
  const [backimg, setBackimg] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false)
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    vehicle_number: '',
    owner_name: '',
    vehicle_class: '',
    maker_name: '',
    mfg_date: '',
    chassis_number: '',
    engine_number: ''
  });




  const validateForm = () => {
    
    let formErrors = {};
    if (!vehicleNumber) formErrors.vehicleNumber = "Vehicle number is required";
    if (!chassisNumber) formErrors.chassisNumber = "Chassis number is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const fetchDetails = async () => {
    if (!validateForm()) return;
    try{
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/users/get-vehicle-info?vehicle_number=${vehicleNumber}&chassis_number=${chassisNumber}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if(!response){
        toast.error("No response from server. Please try again later.");
      }
      if(response.status === 200){
        console.log(response.data);
        setVehicleDetails(response.data);
        setDetailsConfirmed(false);
      }
    }catch(err){
      if (err.request.status === 400) {
        toast.info("Vehicle Doesn't Exists.");
        return;
      }
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session Expired. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to Add Vehicle. Please try again later.");
      }
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true)
    if (validateForm() ) {
      if(!detailsConfirmed){
        toast.warning("Please confirm the details before submitting");
        return;
      }
      try{
        const data = new FormData();
        const fdata = {
          vehicle_number: vehicleDetails.vehicle_number,
          owner_name: vehicleDetails.owner_name,
          vehicle_class: vehicleDetails.vehicle_class,
          maker_name: vehicleDetails.maker_name,
          mfg_date: vehicleDetails.mfg_date,
          chassis_number: vehicleDetails.chassis_number,
          engine_number: vehicleDetails.engine_number,
          rc_img: vehicleDetails.rc_img
        }
      
        data.append("vehicle_data", JSON.stringify(fdata));
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/users/add-vehicle', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }});
        if (!response) {
          toast.error("No response from server. Please try again later.")
          setIsDisabled(false)
          return;
        }
        if (response.status === 200) {
          toast.success('Vehicle added successfully');
          setFormData({
            vehicle_number: '',
            owner_name: '',
            vehicle_class: '',
            maker_name: '',
            mfg_date: '',
            chassis_number: '',
            engine_number: ''
          });
          setSelectedFrontImage(null);
          setSelectedBackImage(null);
          navigate('/dashboard/yourVehicles')
          setIsDisabled(false)
          return
        }
      }catch(err){
        console.log(err)
        if (err.request.status === 400) {
          toast.info("Vehicle Already Exists.");
          return;
        }
        if (err.response) {
          if (err.response.status === 401) {
            toast.error("Session Expired. Please Login Again.");
            navigate('/logout');
          }
          if(err.response.status === 403){
            toast.error("Unable to fetch details. Please Login Again.");
            navigate('/logout');
          }
      }
        if (err.request) {
          toast.error("Unable to Add Vehicle. Please try again later.");
        }
        
      
    } }else {
      toast.error('Please fill all the required fields');
    }
  };


  const handleTryAgain = () => {
    setVehicleNumber("");
    setChassisNumber("");
    setErrors({});
    setVehicleDetails(null);
    setDetailsConfirmed(false);
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="ml-[69px] mt-12 lg:mt-20 lg:ml-64">
        <h1 className="text-3xl lg:ml-14 sm:text-4xl md:text-4xl font-bold text-[#181f6d]">
          Add Vehicle
        </h1>
        <form  onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-64 lg:ml-24 w-[320px] lg:w-[900px] p-6">
            <div>
              <label htmlFor="vehicleNumber" className="font-bold">
                Vehicle Number
              </label>
              <br />
              <input
                type="text"
                value={vehicleNumber}
                onChange={(e) => setVehicleNumber(e.target.value)}
                maxLength={10}
                placeholder="Enter your vehicle number"
                className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
              />
              {errors.vehicleNumber && (
                <p className="text-red-500">{errors.vehicleNumber}</p>
              )}
            </div>
            <div>
              <label htmlFor="chassisNumber" className="font-bold">
                Chassis Number
              </label>
              <br />
              <input
                type="text"
                value={chassisNumber}
                onChange={(e) => setChassisNumber(e.target.value)}
                maxLength={5}
                placeholder="Enter last 5 digits of chassis number"
                className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
              />
              {errors.chassisNumber && (
                <p className="text-red-500">{errors.chassisNumber}</p>
              )}
            </div>
          </div>
          {!vehicleDetails && (
            <button
              type="button"
              onClick={fetchDetails}
              className="mt-4 ml-[96px] lg:ml-[867px] p-2 bg-[#9476fb] text-white border-0 rounded-full py-1 px-3"
            >
              Get Details
            </button>
          )}
          {vehicleDetails && (
            <div className="ml-6   lg:ml-[120px] bg-white rounded-md w-[273px] lg:w-full ">
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-64 lg:w-[855px]">
                <div>
                  <div className="mb-6">
                    <label className="font-bold">Owner Name</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleDetails.owner_name}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  
                  <div className="mb-6">
                    <label className="font-bold">Vehicle Class</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleDetails.vehicle_class}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="font-bold">Company Name</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleDetails.maker_name}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="font-bold">Manufacture Date</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleDetails.mfg_date}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                </div>
                <div>
                  
                  <div className="mb-6">
                    <label className="font-bold">Engine Number</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleDetails.engine_number}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <img
                      src={vehicleDetails.rc_img || imageIcon}
                      alt="License"
                      className="mt-2"
                    />
                  </div>
                  <label className="font-bold item-center ml-16 mb-12 lg:ml-20 mt-0 px-12 py-2 w-full lg:w-[130px] sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]">
                    RC
                  </label>
                </div>
              </div>
              <div className="mt-8">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={detailsConfirmed}
                    onChange={(e) => setDetailsConfirmed(e.target.checked)}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="ml-2 mt-2">Confirm Details</span>
                </label>
                <button
                  type="button"
                  
                  onClick={handleTryAgain}
                  className="ml-4 text-blue-500 hover:underline"
                >
                  Try Again
                </button>
              </div>
            </div>
          )}
          {vehicleDetails && (
            <button
              type="submit"
             
              className="mt-4 mb-8 ml-[225px] lg:ml-[900px] p-2 bg-[#9476fb] text-white border-0 rounded-full py-1 px-3"
            >
              Submit
            </button>
          )}
        </form>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}

export default AddVehicle1;
