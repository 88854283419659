import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import Filters from './Filter';
import '../../../css/Pie.css';

const sampleData = [
  { district: 'Khammam', date: '2024-04-17', amount: 20000, count: 50 },
  { district: 'Bhadradri Kothagudem', date: '2024-04-17', amount: 18000, count: 40 },
  { district: 'Hyd', date: '2024-04-18', amount: 20000, count: 20 },
];

const districtOptions = [
  { value: 'Adilabad', label: 'Adilabad' },
  { value: 'Bhadradri Kothagudem', label: 'Bhadradri Kothagudem' },
  { value: 'Hyderabad', label: 'Hyderabad' },
];

const pieoption = {
  tooltip: { trigger: 'item' },
  legend: { top: '5%', left: 'center' },
  color: ['#b8d7ff', '#7bb4fc'],
  series: [
    {
      name: 'Challans',
      type: 'pie',
      radius: ['20%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: { show: false, position: 'center' },
      emphasis: {
        label: { show: true, fontSize: 20, fontWeight: 'bold' },
      },
      labelLine: { show: false },
      data: [
        { value: 1048, name: 'Total no.of challans created by Police' },
        { value: 735, name: 'Total no.of challans created by Police created' },
      ],
    },
  ],
};

const PieChart = () => {
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDistrictChange = (selectedOptions) => {
    setSelectedDistricts(selectedOptions || []);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredData = sampleData.filter((item) => {
    const districtMatch = selectedDistricts.length === 0 || selectedDistricts.some(option => option.value === item.district);
    const dateMatch = !selectedDate || item.date === selectedDate.toISOString().split('T')[0];
    return districtMatch && dateMatch;
  });

  const option = {
    legend: {},
    tooltip: {},
    dataset: {
      dimensions: ['district', 'Total amount of challans created by Police', 'Total no.of challans created by Police'],
      source: filteredData.map(item => ({
        district: item.district,
        'Total amount of challans created by Police': item.amount,
        'Total no.of challans created by Police': item.count
      }))
    },
    xAxis: { type: 'category' },
    yAxis: [
      { type: 'value', name: 'Total amount of challans created by Police', position: 'left' },
      { type: 'value', name: 'Total no.of challans created by Police', position: 'right' }
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Total amount of challans created by Police',
        itemStyle: { color: '#b8d7ff' }
      },
      {
        type: 'bar',
        yAxisIndex: 1,
        name: 'Total no.of challans created by Police',
        itemStyle: { color: '#7bb4fc' }
      }
    ]
  };

  useEffect(() => {
    const myChart = echarts.init(document.getElementById('bar-chart'));
  
    // Set the chart options
    myChart.setOption(option);
  
    // Add resize listener
    window.addEventListener('resize', myChart.resize);
  
    // Clean up the chart instance and event listener
    return () => {
      myChart.dispose();
      window.removeEventListener('resize', myChart.resize);
    };
  }, [filteredData, option]);
  

  return (
    <>
      <div className="pie-main">
        <Filters
          title="Police Challan"
          districtOptions={districtOptions}
          selectedDistricts={selectedDistricts}
          handleDistrictChange={handleDistrictChange}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
        />
        <div className="pie-split">
          <div className="pie-right">
            <ReactECharts option={pieoption} style={{ height: '400px', width: '100%' }} />
          </div>
          <div className="pie-left">
            <div id="bar-chart" style={{ width: '100%', height: '400px' }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PieChart;
