import React from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.css";
import revroad from "../../assets/revroad.svg";
const Navbar = () => {
  return (
    <>
      <div className="nav-home">
        <div className="right">
          <Link to="/">
            <h1 className="text1 flex gap-1 items-center">
              <img src={revroad} alt="Logo" className="h-6 lg:h-8" /> RevRoad
            </h1>
          </Link>
        </div>
        <div className="left">
          <Link to="/profile">
            <img
              // className="p-overlay-badge"
              alt="Profile"
              src={localStorage.getItem("profile_url")}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
