import React, { useState } from 'react';
import '../css/Challan.css';
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Challan = () => {
  const [challans, setChallans] = useState([]);
  const [challan, setchallan] = useState("")
  const navigate = useNavigate();
  async function fetchChallans() {
    try{
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/common/get-challans',{
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });
      if(!response){
        toast.error("Unable to fetch Challans. Please try again later.")
        return;
      }
      if(!response.data.length){
        toast.error("No Challans Found")
      }
      if(response.status === 200){
        setChallans(response.data.slice(1,));
        setchallan(response.data[0])
      }
    }catch(err){
      if(err.response.status === 404){
        toast.error("No vehicles Found")
        return;
      }
      if(err.response.status === 403){
        toast.error("Unable to fetch details. Please Login Again.");
        navigate('/logout');
      }
      if(err.response.status === 401){
        toast.error("Session Expired. Please login again.")
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.replace('/login')
        return;
      }
      if(err.response){
        toast.error("Unable to fetch Challans. Please try again later.")
      }
    }
  }
 
  useState(() => {
    fetchChallans();
  }, []);
  return (
    <>
    <Sidebar/>
    <Navbar/>
      <div className="chal-main">
        <div className="chal-content">
          <div className="chal-head">
            <h1 className='chal-color po-title'>Your Challans</h1>
            <div className="chal-block-fine">
              <h2>Total Challan</h2>
              <h1>Rs {challan}</h1>
            </div>
          </div>
          <div className="chal-block">
            <div className="chal-vec">
              <h3 className="chal-color ml">Your Vehicles</h3>
              {
                challans.map((challan, index) => (
                  <button key={challan.id} className='chal-vec-btn'>{challan.vehicle_number}</button>
                ))
              }
              
            </div>
            <div className="challan">
              <div className="chal-table">
                <table>
                  <thead>
                    <tr className='t-head'>
                      <th>S.no</th>
                      <th>Cause</th>
                      <th>Image</th>
                      <th>Challan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {challans.map((vehicle, vehicleIndex) => (
                        vehicle.challan_history.map((challan, challanIndex) => (
                            <tr key={`${vehicleIndex}-${challanIndex}`} className='t-data'>
                                <td>{challanIndex + 1}</td>
                                <td>{challan.cause}</td>
                                <td>
                                    <img src={challan.img_url || "-"} alt={`Challan ${challanIndex + 1}`} width="100" />
                                </td>
                                <td>{challan.fine}</td>
                            </tr>
                        ))
                    ))}
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position='top-right' />
    </>
  );
}

export default Challan;
