import React, { useState, useEffect } from "react";
import imageIcon from "../../assets/Image.png";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { RiCloseCircleLine } from "react-icons/ri";

function Report_Accident() {



  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const [areaName, setAreaName] = useState("");
  const [accidentdate, setaccidentdate] = useState(today);
  const [severity, setSeverity] = useState("None");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [reports, setReports] = useState([]);
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState("");


    //Image Modal
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
  
    const openModal = (imageSrc) => {
      setModalImage(imageSrc);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setModalImage("");
    };
  
    const Modal = ({ imageSrc, onClose }) => (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="relative">
          <img
            src={imageSrc}
            alt="Enlarged Violation"
            className="max-w-screen max-h-screen rounded-xl"
          />
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-2 text-white   bg-black bg-opacity-50 px-2 py-1 rounded-lg"
          >
            <RiCloseCircleLine className="text-3xl"/>
          </button>
        </div>
      </div>
    );

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPlaceName(latitude, longitude);
        },
        (error) => {
          handleError(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000, // 10 seconds timeout
          maximumAge: 0, // No cached position
        }
      );
    } else {
      console.error("Geolocation is not available");
    }
  };

  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("An unexpected error occurred.");
        break;
    }
  };

  const getPlaceName = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      if (data.display_name) {
        setAddress(`${data.display_name}`);
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress("Error retrieving address.");
    }
  };

  const validate = () => {
    let validationErrors = {};
    if (!areaName) validationErrors.areaName = "Area name is required";
    if (severity === "None")
      validationErrors.severity = "Severity must be selected";
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const accident_data = {
      area_name: areaName,
      accident_date: accidentdate,
      severity: severity,
      accident_address: address,
    };
    const formData = new FormData();
    formData.append("accident_data", JSON.stringify(accident_data));
    formData.append("accident_img", image);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/api/users/report-accident",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        toast.success("Accident Reported Successfully");
        getAccidents();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }

    // Clear form fields but keep the image state
    setAreaName("");
    setSeverity("None");
    setImageUrl("");
    setErrors({});
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setImageUrl(newImageUrl);
      setImage(file);
    }
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
    setImage(null);
  };
  async function getAccidents() {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/common/get-accidents",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      } else {
        if (response.status === 200) {
          if (response.data.message === "No accidents") {
            setReports([]);
            return;
          }
          setReports(response.data);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  }
  useEffect(() => {
    getAccidents();
    getLocation();
  }, [navigate]);
  return (
    <>
      <Sidebar />
      <Navbar />
      <main className="mt-[15%] lg:mt-[5%] ml-20 lg:ml-32 xl:ml-72">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
          Report Accident
        </h1>
        <div className="flex flex-wrap gap-4 sm:gap-8 md:gap-7 mt-8 sm:mt-10 md:mt-12">
          <div className="w-[300px]  lg:w-[740px] md:w-4/5 rounded-2xl shadow-xl bg-gray-100 p-2 lg:p-5 transition duration-300 hover:bg-gray-200 hover:shadow-2xl">
            <form onSubmit={handleSubmit}>
              <p className="text-lg sm:text-xl ml-4 mt-2 font-semibold mb-3">
                New Report
              </p>
              <hr className="mb-6 border-0 h-0.5 bg-gray-400" />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <label htmlFor="areaName" className="ml-3 text-lg">
                    Area Name
                  </label>
                  <input
                    type="text"
                    name="areaName"
                    id="areaName"
                    placeholder="Enter"
                    className="ml-3 mt-1 p-2 w-[275px] bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-[-5%]"
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                  />
                  {errors.areaName && (
                    <p className="text-red-500 ml-3">{errors.areaName}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="pincode" className="ml-3 text-lg">
                    Your Location
                  </label>
                  <textarea
                    type="text"
                    name="pincode"
                    id="pincode"
                    rows={2}
                    cols={4}
                    className="ml-3 mt-1 p-2 w-[275px] bg-transparent overflow-hidden resize-none border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-[-5%] lg:w-[] lg:min-h-[8vh]"
                    value={address}
                    disabled
                  />
                </div>
                <div>
                  <label htmlFor="severity" className="ml-3 text-lg">
                    Severity
                  </label>
                  <select
                    name="severity"
                    id="severity"
                    className="ml-3 mt-1 p-2 w-[275px] bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    value={severity}
                    onChange={(e) => setSeverity(e.target.value)}
                  >
                    <option value="None">None</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </select>
                  {errors.severity && (
                    <p className="text-red-500 ml-3">{errors.severity}</p>
                  )}
                </div>
                <div className="flex flex-row gap-20 lg:mt-8 justify-center items-center md:items-start">
                  <label className="border-none flex px-3 py-1.5 bg-[#8B68F7] text-white text-xs align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer">
                    Select image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageUpload(e);
                      }}
                      className="hidden"
                    />
                  </label>
                  <button className="border-none lg:w-[83px] font-semibold flex items-center justify-center px-3 py-1.5 bg-[#8B68F7] text-white text-[10px] rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div
            id="Accident-image"
            className="mr-3 lg:mr-0 lg:w-[35%] md:w-[25%] rounded-2xl shadow-xl bg-gray-100 transition duration-300 hover:bg-gray-200 hover:shadow-2xl"
          >
            <div className="flex justify-between px-6 py-3">
              <h3 className="text-bold font-bold">Image<span className="text-red-600">*</span></h3>
              <h3
                className="text-semibold text-[#8B68F7] cursor-pointer"
                onClick={handleRemoveImage}
              >
                Remove
              </h3>
            </div>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Selected"
                className="mt-2 rounded-3xl p-2"
              />
            ) : (
              <img src={imageIcon} alt="Placeholder" className="mt-4 p-4" />
            )}
          </div>
        </div>
        <div className="rounded-xl mr-4 mt-12 shadow-xl hover:bg-gray-200 bg-gray-100 mb-12 transition duration-300 p-6">
          <div className="flex justify-between pb-4">
            <h3 className="text-2xl font-semibold">Reports</h3>
            <h3 className="text-sm text-[#8B68F7] cursor-pointer">
              <Link to="/dashboard/accidentHistory">View More &gt;</Link>
            </h3>
          </div>
          <div className="overflow-x-auto">
            {reports.length === 0 ? (
              <div className="message">You Have no reports</div>
            ) : (
              <table className="table-auto w-full text-xs lg:text-sm">
                <thead className="border-t-2 border-b-2 border-gray-500">
                  <tr>
                    <th className="px-4 py-2 text-left">Date</th>
                    <th className="px-4 py-2 text-left">Area Name</th>

                    <th className="px-4 py-2 text-center">Severity</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.slice(0, 2).map((report, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 text-left">
                        {report.accident_date}
                      </td>
                      <td className="px-4 py-2 text-left">
                        {report.area_name}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {report.severity}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {report.accident_address.split(",")[0]}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <img
                            src={report.accident_img_url}
                            alt="Report"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                            onClick={() => openModal(report.accident_img_url)}
                            className="w-16 h-12 object-cover rounded-full"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </main> {isModalOpen && <Modal imageSrc={modalImage} onClose={closeModal} />}


      <ToastContainer position="top-right" />
    </>
  );
}

export default Report_Accident;
