// ReportLicense.js
import React, { useEffect, useState } from "react";
import "../css/License.css"; // Assuming you have styles defined here
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import LicenseImage from "../../assets/License-image.png";
import CarImage from "../../assets/CarL.png";
import AddIcon from "@mui/icons-material/Add";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function YourLicense() {
  const location = useLocation();
  const { ReportLicense } = location.state || {};
  const navigate = useNavigate();
  const [license, setlicense] = useState([]);
  async function removeLicense(license_number) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
        `/api/users/remove-license?license_number=${license_number}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        toast.success("License removed successfully");
        fetchdata();
        return;
      }
    } catch (err) {
      if(err.response.status === 404){
        toast.error("License not found. Please try again later.");
        return;
      }
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session Expired. Please Login Again.");
          navigate("/logout");
        }
        if(err.response.status === 403){
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to remove license. Please try again later.");
      }
    }
  }
  async function fetchdata() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/api/common/get-licenses`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        console.log(response.data);
        if (response.data.message === "No licenses added") {
          alert("No licenses found");
          toast.info("No licenses found");
          setlicense([]);
          return;
        }
        setlicense(response.data);
        return;
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session Expired. Please Login Again.");
          navigate("/logout");
        }
        if(err.response.status === 403){
          toast.error("Session Expired. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch license details. Please try again later.");
      }
    }
  }
    useEffect(() => {
      fetchdata();
    }, [navigate]);
    return (
      <>
        <Navbar />
        <Sidebar />

        <main className="main-container">
          <div className="but">
            <h1 className="main-title">Your License</h1>
            <button
              className="button"
              onClick={() => {
                navigate("/dashboard/AddYourLicense");
              }}
            >
              ADD <AddIcon />
            </button>
          </div>
          <div className="row">
            {license.map((license) => (
              <div className="image-container">
                <img
                  src={LicenseImage}
                  alt="License"
                  className="license-image"
                />
                <div className="overlay-text">
                  <p>{license.father_name}</p>
                  <p>issue Date:{license.issue_date}</p>
                  <p>Expire Date:{license.expiry_date}</p>
                  <p>License Class:{license.license_class}</p>
                  <button
                    className="rembut"
                    onClick={() => {
                      removeLicense(license.license_number);
                    }}
                  >
                    Remove
                  </button>
                </div>
                <img src={CarImage} alt="Car" className="car-image" />
              </div>
            ))}
          </div>
        </main>
        <ToastContainer position="top-right" />
      </>
    );
  }


export default YourLicense;
