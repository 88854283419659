// src/components/BarChart.js
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import '../../../css/Filter.css';

const BarChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    // Original data and labels
    const data = [1000, 2420, 1440, 1240];
    const labels = ['North', 'West', 'East', 'South'];

    // Sort and limit the data
    const sortedData = data
      .map((value, index) => ({ value, label: labels[index] }))
      .sort((a, b) => b.value - a.value) // Sort by value in decreasing order
        

    const sortedValues = sortedData.map(item => item.value);
    const sortedLabels = sortedData.map(item => item.label);

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: sortedLabels,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Challans',
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            color: '#b8d7ff'
          },
          data: sortedValues
        }
      ]
    };

    chartInstance.setOption(option);

    return () => {
      chartInstance.dispose();
    };
  }, []); // Empty dependency array ensures this effect runs once

  return (
    <>
      <div className="heading">
        <h1>Challans by Region</h1>
      </div>
      <div
        ref={chartRef}
        style={{ width: '100%', height: '400px' }} // Responsive size
      ></div>
    </>
  );
};

export default BarChart;
