import React, { useState, useRef, useEffect } from 'react';
import imageIcon from "../../assets/Image.png";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DravingLicense() {
  const navigate = useNavigate();
  const [aadhaar, setAadhaar] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpSent, setOtpSent] = useState(false);
  const [otpConfirmed, setotpConfirmed] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);
  const [pincode, setPincode] = useState("")
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [userData, setUserData] = useState({});
  const [rtoDetails, setRtoDetails] = useState(false); 
  const [appointmentDate, setappointmentDate] = useState('');
  const [appointmentTime, setappointmentTime] = useState(''); 
  const[rtolocation,setrtolocation]=useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const otpRefs = useRef([]);
  const [rtolocations, setrtolocations] = useState([]);
  const inputRefs = useRef([]);
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleAadhaarChange = (e) => {
    setAadhaar(e.target.value);
  };
  const fetchLocations = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/common/get-options?doctype=rtolocations');
        if (!response) {
            toast.error("Unable to connect. Please try again later.");
        }
        setrtolocations(response.data);
    } catch (error) {
        toast.error("Failed to load RTO locations.");
    }
};


  const handleSendOtp = async () => {
    if (aadhaar.length === 12) {
      try{
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/auth/verify-mobile',{"mobile_number":localStorage.getItem('mobile_number')});
      if (!response) {
        toast.error('No response from server');
        return;
      }
      if (response.status === 200){
        toast.success('OTP sent successfully');
        setOtpSent(true);
      }
    } catch (err) {
      if(err.response.status === 403){
        toast.error("Unable to fetch details. Please Login Again.");
        navigate('/logout');
      }
      if(err.response.status === 401){
        toast.error("Session Expired. Please login again.")
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.replace('/login')
        return;
      }
      if(err.response){
        toast.error("Unable to send OTP. Please try again later.")
      }
    }
    } else {
      toast.error('Please enter a valid 12-digit Aadhaar number.');
    }
  }

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    if (value && index < 6 - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpConfirm = async() => { 
    if (otp.join('').length !== 6 || otp.includes('')) {
      alert('Please enter a 6-digit OTP.');
      return;
    }
    else{
      try{
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/auth/verify-otp?use_case=aadhar_verification',{"mobile_number":localStorage.getItem("mobile_number"),"otp":otp.join('')});
        if (!response) {
          toast.error('No response from server');
          return;
        }
        if (response.status === 200){
          setotpConfirmed(true)
          const token = localStorage.getItem('token');
          try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/aadhaar-data?aadhaar=${aadhaar}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            if (!response) {
              toast.error('No response from server. Please try again later.');
              return;
            }
            if (response.status === 200){
              toast.success('Details fetched successfully');
              setUserData(response.data.data);
              setPincode(response.data.data.address.pincode);
              
            }
          }catch(err){
            if (err.response) {
              if (err.response.status === 401) {
                toast.error("Unable to fetch details. Please Login Again.");
                navigate('/logout');
              }
              if(err.response.status === 403){
                toast.error("Unable to fetch details. Please Login Again.");
                navigate('/logout');
              }
            }
            if (err.request) {
              toast.error("Unable to fetch details. Please try again later.");
            }
          }
        }
      
      }
    catch(err){
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if(err.response.status === 403){
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
     }
    }
    
  }
  


  const handleConfirmDetails = () => {
    if(otpConfirmed){
      setRtoDetails(true);
      return
    }
    const { name, gender, phoneNumber, dateOfBirth, relation, pincode, address } = userData;
    if (!name || !gender || !phoneNumber || !dateOfBirth || !relation || !pincode || !address) {
      toast.error('Please fill in all the details.');
      return;
    } 
    setRtoDetails(true); 
  };

  const handleRtoConfirm = async() => {
    if (!appointmentDate || !appointmentTime || !rtolocation) {
      toast.error('Please fill in all the details.');
      return;
    }
    try{
      const formdata = new FormData();
      formdata.append('user_data', JSON.stringify(userData));
      formdata.append('appointment_data',JSON.stringify({
        appointmentDate:appointmentDate,
        aappointmentTime:appointmentTime,
        rtoLocation:rtolocation
      }))
      formdata.append('image',image)
      console.log(formdata)
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/dl-slot-booking`, formdata,{
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response) {
      toast.error('No response from server');
      return;
    }
    if (response.status === 200){
      toast.success('RTO Appointment Confirmed');
      navigate('/dashboard/drivinglincense')
      
    }
    }catch(err){
      if(err.request.status === 400){
        toast.error('Previous DL slot booking pending')
        return
      }
      if (err.request) {
        toast.error('Error connecting to server');
      }
      else{
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  const handleManualEntry = () => {
    setManualEntry(true);
    setotpConfirmed(true);
  };
 
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setImageUrl(newImageUrl);
      setImage(file);
    }
  };


  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time part for accurate comparison

    if (selectedDate < today) {
      setErrorMessage("Please select a future date.");
    } else {
      setErrorMessage("");
      setappointmentDate(e.target.value);
    }
  };

  const todayDateString = new Date().toISOString().split("T")[0];

  useEffect(()=>{
    fetchLocations();
  },[])
  return (
    <>
      <Sidebar />
      <Navbar />
      <main className="mt-14 mx-5 mb-12 ml-20 md:ml-20 lg:ml-32 xl:ml-72">
        <h1 className="text-3xl  sm:text-4xl md:text-5xl font-bold">
          Apply Driving License
        </h1>
        <div className=" lg:w-[100%] md:w-[70%] rounded-2xl shadow-xl pt-3 bg-gray-100 lg:p-10 pb-5 lg:pb-12 transition duration-300 hover:bg-gray-200 hover:shadow-2xl mt-4 lg:mt-6">
          {!otpConfirmed && !manualEntry ? (
            <>
              <div>
                <div className="grid gap-3 grid-cols-1 md:grid-cols-3">
                  <div>
                    <label htmlFor="Aadharnumber" className="ml-3 font-bold">
                      Enter Aadhaar details
                    </label>
                    <br />
                    <input
                      type="tel"
                      value={aadhaar}
                      onChange={handleAadhaarChange}
                      placeholder="Enter your 12-digit Aadhaar number"
                      className="ml-3 mt-1 p-2 w-[80%] sm:w-4/5 bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                    />
                    <button
                      onClick={handleSendOtp}
                      className="mt-0 ml-[50%] md:ml-0 md:ml-44 border-none flex px-4 py-3 bg-[#8B68F7] text-white text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer"
                    >
                      Send OTP
                    </button>
                  </div>

                  {!otpSent && (
                    <div className="lg:w-[80%] lg:ml-48">
                      <div className="flex items-center justify-center md:justify-start">
                        <h1 className="font-bold">(Or)</h1>
                      </div>
                      <div className="flex flex-col items-center md:items-start">
                        <h1 className="font-bold mt-3 mb-[-6%] ml-[-30%]">Enter details manually</h1>
                        <br />
                        <button
                          onClick={handleManualEntry}
                          className="ml-[-8%]  px-10 py-3 border-2 border-black bg-[#d9d9d9] text-black font-bold text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer"
                        >
                          Application Form
                        </button>
                      </div>
                    </div>
                  )}
                  {otpSent && (
                    <div className="lg:ml-48 flex items-center justify-center md:justify-start col-span-2 md:col-span-1">
                      <div className="w-full">
                        <h2 className="text-xl font-bold mb-4">Enter OTP</h2>
                        <div className="flex justify-center">
                          {otp.map((digit, index) => (
                            <input
                            inputMode="numeric"
                              key={index}
                              type="text"
                              ref={(input) =>
                                (inputRefs.current[index] = input)
                              }
                              value={digit}
                              onChange={(e) => handleOtpChange(e, index)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              maxLength="1"
                              className="border border-gray-300 p-2 rounded w-10 mx-1 text-center"
                            />
                          ))}
                        </div>
                        <button
                          onClick={handleOtpConfirm}
                          className="mt-4 w-full border-none flex justify-center px-4 py-3 bg-[#8B68F7] text-white text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer"
                        >
                          Fetch Details
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {!rtoDetails ? (
                <>
                  {manualEntry || otpConfirmed ? (
                    <div className="">
                      <h2 className="text-2xl ml-12 lg:ml-0 pt-2 lg:pt-0 font-bold mb-4">
                        Application Form
                      </h2>
                      {otpConfirmed && !manualEntry && (
                        <div className="mb-4 ">
                          <label className="ml-3 font-bold">
                            Aadhaar Number
                          </label>
                          <input
                            type="text"
                            value={aadhaar}
                            readOnly
                            className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] "
                          />
                        </div>
                      )}

                      <div className="flex flex-wrap lg:gap-x-10 border-2 m-1 lg:p-10  rounded-md border-black">
                        <div className="mb-0">
                          {!otpSent && (
                            <>
                              <div className="mt-3 lg:mt-0">
                                <label
                                  htmlFor="Aadharnumber"
                                  className="ml-3 font-bold"
                                >
                                  Enter Aadhaar details
                                </label>
                                <br />
                                <input
                                  type="tel"
                                  value={aadhaar}
                                  onChange={handleAadhaarChange}
                                  placeholder="Enter your 12-digit Aadhaar number"
                                  className="ml-3 mt-1 p-2  bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                                />
                              </div>
                            </>
                          )}
                          <div>
                            <label htmlFor="Name" className="ml-3 font-bold">
                              Name
                            </label>
                            <br />
                            <input
                              type="text"
                              name="Name"
                              id="Name"
                              placeholder="Enter your name"
                              value={userData.name}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  name: e.target.value,
                                })
                              }
                              className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                            />
                          </div>
                          <div className="mb-6 lg:mb-0">
                            <label htmlFor="Gender" className="ml-3 font-bold">
                              Gender
                            </label>
                            <br />
                           <select className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                           value={userData.gender==='M'?"Male":"Female"}
                           onChange={(e) =>
                             setUserData({
                               ...userData,
                               gender: e.target.value,
                             })
                           }
                           id="Gender"
                           >
                            <option value="default">Select your gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                           </select>
                          </div>
                          <div className="mb-6 lg:mb-0">
                            <label
                              htmlFor="phone Number"
                              className="ml-3 font-bold"
                            >
                              Phone Number
                            </label>
                            <br />
                            <input
                              type="text"
                              name="phonenumber"
                              id="phonenumber"
                              placeholder="Enter your phonenumber"
                              value={localStorage.getItem('mobile_number')}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  phoneNumber: e.target.value,
                                })
                              }
                              className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                            />
                          </div>

                          <div className="mb-10 lg:mb-0">
                            <label
                              htmlFor="dateofBirth"
                              className="ml-3 font-bold"
                            >
                              Date of birth
                            </label>
                            <br />
                            <input
                              type="date"
                              name="dateofBirth"
                              id="dateofBirth"
                              placeholder="Enter your DOB"
                              value={userData.date_of_birth}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  dateOfBirth: e.target.value,
                                })
                              }
                              className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label htmlFor="SOFD" className="ml-3 font-bold">
                            Son of/Daughter of
                          </label>
                          <br />
                          <input
                            type="text"
                            name="sofd"
                            id="sofd"
                            placeholder="Enter Relation"
                            className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                            value={userData.care_of}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                relation: e.target.value,
                              })
                            }
                          />
                          <br /> <br />
                          <div className="mb-6 lg:mb-0">
                            <label htmlFor="pincode" className="ml-3 font-bold">
                              Pincode
                            </label>
                            <br />

                            <input
                              type="number"
                              name="pincode"
                              id="pincode"
                              placeholder="Enter"
                              className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                              value={pincode || userData.pincode}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  pincode: e.target.value,
                                })
                              }
                            />
                          </div>
                          <br />
                          <div className="mb-10 lg:mb-0">
                            <label className="ml-3 font-bold">Address</label>
                            <br />
                            <textarea
                              type="textarea"
                              value={userData.full_address}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  address: e.target.value,
                                })
                              }
                              className="ml-3 mt-1 p-2 w-full h-32 sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                            >
                              </textarea>
                          </div>
                        </div>
                        <div className="mb-6 w-[97%] ml-1  lg:w-[30%]">
                          <label className="font-bold ml-3 ">Picture</label>
                          <div
                            id="divinglicense-image"
                            className="ml-46 mt-1 lg:mb-6 lg:w-[95%] md:w-[95%] rounded-2xl shadow-xl bg-gray-100 transition duration-300 hover:bg-gray-200 hover:shadow-2xl"
                          >
                            <div className="flex justify-between px-6 py-3">
                              {/* <h3 className="text-bold font-bold ">Image</h3> */}
                            </div>
                            {imageUrl ? (
                              <img src={imageUrl} alt="Selected" />
                            ) : (
                              <img
                                src={imageIcon}
                                alt="Placeholder"
                                className="mt-4 p-4"
                              />
                            )}
                          </div>
                          <input type="file" name="image" id="image_aadhaar" hidden  onChange={(e) => {handleImageUpload(e)}}/>
                            <button onClick={() => document.getElementById('image_aadhaar').click()}>
                              
                              Add Image +

                            </button>
                          <button
                            onClick={handleConfirmDetails}
                            className="ml-40 lg:ml-32 mt-12 lg:mt-56 border-black border-2 flex px-6 py-1 bg-[#8B68F7] text-white text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer"
                          >
                            Confirm
                          </button>
                        </div>
                        <div />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="w-[80%]">
                  <h2 className="text-2xl font-bold mb-4">Application Form</h2>
                  <div className="border-2 rounded-md">
                    <div className="mb-0">
                      <div>
                        <label htmlFor="rtoloc" className='text-1xl'>Select RTO Location :</label><br />
                            <select name="rtoloc" id="rtoloc" value={rtolocation} onChange={(e)=>{setrtolocation(e.target.value)}} className="input-field" required>
                              <option value="">Select RTO Location</option>
                                {rtolocations.map((code,index) => (
                                    <option key={index} value={code}>{code}</option>
                                ))}
                            </select>
                      </div>
                      <div>
      <label className="ml-3 font-bold">Select Date</label>
      <br />
      <input
        name="appointmentDate"
        type="date"
        value={appointmentDate}
        min={todayDateString}
        onChange={handleDateChange}
        className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
      />
      {errorMessage && (
        <p className="ml-3 text-red-500">{errorMessage}</p>
      )}
    </div>
                      <div>
                        <label className="ml-3 font-bold">Select Time</label>
                        <br />
                        <input
                          type="time"
                          name="appointmentTime"
                          value={appointmentTime}
                          onChange={(e) => setappointmentTime(e.target.value)}
                          className="ml-3 mt-1 p-2 w-full sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6"
                        />
                      </div>
                      <button
                        onClick={handleRtoConfirm}
                        className="ml-[80%] border-none px-4 py-3 bg-[#8B68F7] text-white text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer"
                      >
                        Confirm
                      </button>
                      <br />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <ToastContainer position='top-right' />
        </div>
      </main>
    </>
  );
}

export default DravingLicense;
