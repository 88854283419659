import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';
import '../../../css/Filter.css'

const EChartsComponent = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const option = {
      legend: {},
      tooltip: {},
      dataset: {
        dimensions: ['product', 'Total', 'Compiled', 'UnCompiled'],
        source: [
          { product: 'Inspections', Total: 30, Compiled: 20, UnCompiled: 10 },

        ]
      },
      xAxis: { type: 'category' },
      yAxis: {},
      series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
    };

    myChart.setOption(option);

    // Resize chart on window resize
    window.addEventListener('resize', myChart.resize);

    // Cleanup on component unmount
    return () => {
      myChart.dispose();
      window.removeEventListener('resize', myChart.resize);
    };
  }, []);

  return (
    <>
     <div className="heading">
        <h1>Inspections</h1>
     </div>
     <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
    </>
  );
};

export default EChartsComponent;
