import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar';
import axios from 'axios';
import { useSpring, animated, a } from '@react-spring/web';
import AddIcon from '@mui/icons-material/Add';
import '../css/Home.css';
import Navbar from './Navbar';
import Loader from './Loader';



function Home() {
  const [data, setData] = useState(null);


  const navigate = useNavigate();

  async function get_info() {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get_info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response) {
        toast.info("No response from server. Please try again later.")
        return;
      }

      if (response.status === 200) {
        toast.success("Welcome to your Traffic dashboard!")
        setData(response.data);
        localStorage.setItem('profile_url', response.data['profile_picture_url']);
        localStorage.setItem('mobile_number', response.data['mobile_number']);
        localStorage.setItem('email', response.data['email']);
        localStorage.setItem('role',response.data['role']);
        localStorage.setItem('name', response.data['name']);
        if(response.data['role'] === 'reviewer'){
          navigate('/reviewer');
        }
        if(response.data['role'] === 'officer'){
          navigate('/Officer/dashboard/home');
        }
        if(response.data['role'] === 'commissioner'){
          navigate('/commissioner/dashboard/home');
        }
        // window.location.reload();
        
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if(err.response.status === 403){
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
      
    }
  }

  useEffect(() => {
    get_info();
    
  }, [navigate]);

  const { number } = useSpring({
    from: { number: 0 },
    number: data ? data.points : 0,
    delay: 200,
    config: { mass: 1, tension: 120, friction: 14 },
  });

  if (!data) return <div>
    <Loader/>
  </div>;

  return (
    <>
      <Sidebar />
      <Navbar />
      <div className='home-cont '>
        <h2 className='text-4xl'>Welcome Back,</h2>
        <h1 className='text-5xl font-bold mt-3'>{data.name}</h1>
        <div className="cont">
          <div className="cont-left">
            <div className="report">
              <div className="title">
                <h4>Your Reports</h4>
                <Link to="/dashboard/YourReports">
                  <span className='violet'>View More</span><ChevronRightIcon />
                </Link>
              </div>
              {/* <div className="line"></div> */}
              <div className="table">
              {data.violations.length === 0 ? (
        <div className="no-reports-message">
          You have no reports.
        </div>
      ) : (
        <div className="flex flex-col items-center w-[100%]">
          {/* Header */}
        <div className="flex w-full bg-white shadow-md rounded-lg p-1 h-15 mb-3">
          <div className="flex-1 px-4 py-2 text-left">S.No</div>
          <div className="flex-1 px-4 py-2 text-center">Vehicle Number</div>
          <div className="flex-1 px-4 py-2 text-center">Violation</div>
          <div className='flex-1 px-4 py-2 text-center'>Status</div>
          

        </div>
        {data.violations.map((row, index) => (
            <div
            key={index}
            className="flex w-full bg-white shadow-md rounded-lg p-1 h-15 mb-3 transition-shadow duration-300 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]"
          >
            <div className="flex-1 px-4 py-2 text-left">{index + 1}</div>
            <div className="flex-1 px-4 py-2 text-center">{row.vehicle_number}</div>
            <div className="flex-1 px-4 py-2 text-center">{row.violation_type}</div>
            <div className="flex-1 px-4 py-2 text-center">{row.status}</div>

            </div>
          ))}

        </div>
      )}
              </div>
            </div>
            <div className="pending">
              <div className="title">
                <h4>Your Challans</h4>
                <Link to="/dashboard/Challan">
                  <span className='violet'>View More</span><ChevronRightIcon />
                </Link>
              </div>
              {/* <div className="line"></div> */}
              <div className="table">
              {/* <div className="table"> */}
              {data.challan_history.length ===0 ? (
        <div className="no-reports-message">
          You have no challans.
        </div>
      ) : (
        <div className="flex flex-col items-center w-[100%]">
          {/* Header */}
        <div className="h-16 p-1 flex w-full bg-white shadow-md rounded-lg mb-3">
          <div className="flex-1 px-4 py-2 text-left">S.No</div>
          <div className="flex-1 px-4 py-2 text-center">Cause</div>
          <div className="flex-1 px-4 py-2 text-center">Image</div>
          <div className='flex-1 px-4 py-2 text-center'>Challan</div>
        </div>
        {data.challan_history.map((vehicle, vehicleIndex) => (
              vehicle.map((challan, challanIndex) => (

            <div
            key={`${vehicleIndex}-${challanIndex}`}
            className="h-16 p-1 flex w-full bg-white shadow-md rounded-lg mb-3 transition-shadow duration-300 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]"
          >
            <div className="flex-1 px-4 py-2 text-left">{challanIndex + 1}</div>
            <div className="flex-1 px-4 py-2 text-center">{challan.cause}</div>
            <div className="flex-1 flex justify-center items-center">
              <img
                src={challan.img_url || "-"}
                alt={`Challan ${challanIndex + 1}`}
                className="w-16 h-12 object-cover"
              />
            </div>
            <div className="flex-1 px-4 py-2 text-center">{challan.fine}</div>

            </div>
              ))
          ))}

        </div>
      )}
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="cont-right">
            <div className="points">
              <div className="po">
                <h2>Points</h2>
                <Link to="/dashboard/Points">
                  <span className='violet'>History</span><ChevronRightIcon />
                </Link>
              </div>
              <animated.h2 className='text-6xl pp'>{number.to(n => n.toFixed(0))}</animated.h2>
            </div>
            <div className="mob-cont">
              <div className="mob-reports">
              <div className="title">
                <h4>Your Reports</h4>
                <Link to="/dashboard/YourReports">
                  <span className='violet'>View More</span><ChevronRightIcon />
                </Link>
              </div>
              <div className="mo-table">
              {data.violations.length === 0 ? (
        <div className="no-reports-message">
          You have no reports.
        </div>
      ) : (
        <div className="flex flex-col items-center w-[100%]">
          {/* Header */}
        <div className="flex w-[100%] bg-white shadow-md rounded-lg p-1 h-15 mb-3">
          <div className="flex-1 px-4 py-2 text-left">S.No</div>
          <div className="flex-1 px-4 py-2 text-center">Vehicle Number</div>
          <div className="flex-1 px-4 py-2 text-center">Violation</div>
          <div className='flex-1 px-4 py-2 text-center'>Status</div>
          

        </div>
        {data.violations.map((row, index) => (
            <div
            key={index}
            className="flex w-[100%] bg-white shadow-md rounded-lg p-1 h-15 mb-3 transition-shadow duration-300 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]"
          >
            <div className="flex-1 px-4 py-2 text-left">{index + 1}</div>
            <div className="flex-1 px-4 py-2 text-center">{row.vehicle_number}</div>
            <div className="flex-1 px-4 py-2 text-center">{row.violation_type}</div>
            <div className="flex-1 px-4 py-2 text-center">{row.status}</div>

            </div>
          ))}

        </div>
      )}
              </div>
              </div>
              <div className="mob-reports">
              <div className="title">
                <h4>Your Challans</h4>
                <Link to="/dashboard/Challan">
                  <span className='violet'>View More</span><ChevronRightIcon />
                </Link>
              </div>
              <div className="mob-table">
              {data.challan_history.length ===0 ? (
        <div className="no-reports-message">
          You have no challans.
        </div>
      ) : (
        <div className="flex flex-col items-center w-[100%]">
          {/* Header */}
        <div className="h-16 p-1 flex w-full bg-white shadow-md rounded-lg mb-3">
          <div className="flex-1 px-4 py-2 text-left">S.No</div>
          <div className="flex-1 px-4 py-2 text-center">Cause</div>
          <div className="flex-1 px-4 py-2 text-center">Image</div>
          <div className='flex-1 px-4 py-2 text-center'>Challan</div>
        </div>
        {data.challan_history.map((vehicle, vehicleIndex) => (
              vehicle.map((challan, challanIndex) => (

            <div
            key={`${vehicleIndex}-${challanIndex}`}
            className="h-16 p-1 flex w-full bg-white shadow-md rounded-lg mb-3 transition-shadow duration-300 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]"
          >
            <div className="flex-1 px-4 py-2 text-left">{challanIndex + 1}</div>
            <div className="flex-1 px-4 py-2 text-center">{challan.cause}</div>
            <div className="flex-1 flex justify-center items-center">
              <img
                src={challan.img_url}
                alt={`Challan ${challanIndex + 1}`}
                className="w-16 h-12 object-cover"
              />
            </div>
            <div className="flex-1 px-4 py-2 text-center">{challan.fine}</div>

            </div>
              ))
          ))}

        </div>
      )}
              </div>
              </div>
            </div>
            <div className="vechiles">
              <div className="title">
                <h4>Your Vehicles</h4>
                <Link to="/dashboard/yourVehicles"><span className='violet'>Manage</span><ChevronRightIcon/> </Link>
              </div>
              <div className="line"></div>
              <div className="num">
                { data.vehicles && data.vehicles.length > 0 ? data.vehicles.map((vehicle, index) => (
                <div className="vec-num">
                  <ChevronRightIcon/><span>{vehicle}</span>
                </div>)) : <Link to="/dashboard/AddVehicle"><button className="lin-btn"> <AddIcon/> Add Vehicles</button></Link>}
              </div>
            </div>
            <div className="vechiles">
              <div className="title">
                <h4>License</h4>
                <Link to="/dashboard/YourLicense"><span className='violet'>Manage</span><ChevronRightIcon/> </Link>
              </div>
              <div className="line"></div>
              <div className="num">
               {data.licenses && data.licenses.length > 0 ? data.licenses.map((license, index) => (
                <div className="vec-num">
                  <ChevronRightIcon/><span>{license}</span>
                </div>)) : 
                  <Link to="/dashboard/AddYourLicense"><button className="lin-btn"> <AddIcon/> Add License</button></Link>  
}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position='top-right' />
    </>
  );
}

export default Home;
