import React, { useEffect, useState } from "react";
import imageIcon from "../../assets/Image.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleLine } from "react-icons/ri";
const ReviewerHome = () => {
  const [activeTab, setActiveTab] = useState("violations");
  const [view, setView] = useState("pending");
  const navigate = useNavigate();
  const [pending, setPending] = useState([]);
  const [reviewed, setReviewed] = useState([]);
  const [recordpending, setrecordpending] = useState([]);
  const [recordreviewed, setrecordreviewed] = useState([]);

  const [pendingViolations, setPendingViolations] = useState([]);
  const [pendingAccidents, setPendingAccidents] = useState([]);
  const [pendingTrafficJams, setPendingTrafficJams] = useState([]);
  const [reviewedViolations, setReviewedViolations] = useState([]);
  const [reviewedAccidents, setReviewedAccidents] = useState([]);
  const [reviewedTrafficJams, setReviewedTrafficJams] = useState([]);

  //Image Modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage("");
  };

  const Modal = ({ imageSrc, onClose }) => (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative">
        <img
          src={imageSrc}
          alt="Enlarged Violation"
          className="max-w-screen max-h-screen rounded-xl"
        />
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-white   bg-black bg-opacity-50 px-2 py-1 rounded-lg"
        >
          <RiCloseCircleLine className="text-3xl"/>
        </button>
      </div>
    </div>
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleViewClick = (view) => {
    setView(view);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/reviewer/get-review-data",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setPendingViolations(response.data.pending_violations.reverse());
        setPendingAccidents(response.data.pending_accidents.reverse());
        setPendingTrafficJams(response.data.pending_trafficjams.reverse());
        setReviewedViolations(response.data.your_reviewed_violations.reverse());
        setReviewedAccidents(response.data.your_reviewed_accidents.reverse());
        setReviewedTrafficJams(
          response.data.your_reviewed_trafficjams.reverse()
        );
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  };

  const acceptViolation = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/reviewer/accept-violation?vid=" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Violation Accepted Successfully");
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to accept violation. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to accept violation. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to accept violation. Please try again later.");
      }
    }
  };
  const acceptAccident = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/reviewer/accept-accident?aid=" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Accident Accepted Successfully");
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to accept accident. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to accept accident. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to accept accident. Please try again later.");
      }
    }
  };
  const acceptTrafficJam = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/reviewer/accept-trafficjam?jid=" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Traffic Accepted Successfully");
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to accept traffic. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to accept traffic. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to accept traffic. Please try again later.");
      }
    }
  };
  const rejectViolation = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/reviewer/reject-violation?vid=" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Violation Rejected Successfully");
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to reject violation. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to reject violation. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to reject violation. Please try again later.");
      }
    }
  };
  const rejectAccident = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/reviewer/reject-accident?aid=" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Accident Rejected Successfully");
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to reject accident. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to reject accident. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to reject accident. Please try again later.");
      }
    }
  };
  const rejectTrafficJam = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/reviewer/reject-trafficjam?jid=" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Traffic Jam Rejected Successfully");
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to reject traffic jam. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to reject traffic jam. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to reject traffic jam. Please try again later.");
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("role") !== "reviewer") {
      navigate("/logout");
    }
    fetchData();
  }, []);

  const renderTableContent = () => {
    if (activeTab === "violations") {
      if (view === "pending") {
        return (
          <div className="bg-white text-xs lg:text-base rounded-lg p-4">
            <br />
            <div className="flex flex-col items-center lg:w-[1300px] ml-40 lg:ml-32">
              {/* Table */}
              <table className="w-full bg-white  rounded-lg mb-4 border-separate border-spacing-y-4">
                <thead className="bg-white shadow-md rounded-lg">
                  <tr>
                    <th className="px-4 py-2 text-left ">S.No</th>
                    <th className="px-4 py-2 text-left ">Date</th>
                    <th className="px-4 py-2 text-center">Area Name</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                    <th className="px-4 py-2 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody >
                  {pendingViolations.map((item, index) => (
                    <tr
                      key={index}
                      className="transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)] bg-white rounded-lg"
                    >
                      <td className="px-4 py-2 text-left rounded-l-lg">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.violation_date}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.vehicle_number}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.violation_type}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <img
                          src={item.violation_img_url}
                          alt="Violation"
                          className="w-16 h-12 object-cover mx-auto rounded-lg"
                          onClick={() => openModal(item.violation_img_url)}
                        />
                      </td>
                      <td className="px-4  py-2 text-center rounded-r-lg space-y-2">
                        <button
                          className="bg-[#00A8F0] text-white px-2 py-1 rounded"
                          onClick={() => acceptViolation(item.vid)}
                        >
                          Accept
                        </button>
                        <button
                          className="bg-[#F06960] text-white px-2 py-1 rounded w-full lg:w-auto lg:ml-2"
                          onClick={() => rejectViolation(item.vid)}
                        >
                          Deny
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      } else if (view === "reviewed") {
        return (
          <div className="bg-white text-xs lg:text-base rounded-lg p-3">
            <br />
            <div className="flex flex-col items-center lg:w-[1300px] ml-44 lg:ml-32">
              {/* Table */}
              <table className="w-full  bg-white  rounded-lg mb-4 border-separate border-spacing-y-4 p-2">
                <thead className="bg-white shadow-md rounded-lg">
                  <tr>
                    <th className="px-4 py-2 text-left">S.No</th>
                    <th className="px-4 py-2 text-center ">Date</th>
                    <th className="px-4 py-2 text-center">Area Name</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                    <th className="px-4 py-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reviewedViolations.map((item, index) => (
                    <tr
                      key={index}
                      className=" duration-300 transition-all overflow-hidden hover:scale-105 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]  shadow-lg bg-white rounded-lg"
                    >
                      <td className="px-4 py-2 rounded-l-lg text-left ">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.violation_date}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.vehicle_number}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.violation_type}
                      </td>
                      <td className="px-4 py-2  text-center">
                        <img
                          src={item.violation_img_url}
                          alt="Violation"
                          onClick={() => openModal(item.violation_img_url)}
                          
                          className="w-16 h-12 object-cover mx-auto rounded-md cursor-pointer"
                        />
                      </td>
                      <td className="px-4 py-2 rounded-r-lg text-center">
                        <button
                          className={`px-2 py-1 rounded ${
                            item.status === "accepted"
                              ? "bg-[#00A8F0] text-white"
                              : "bg-[#F06960] text-white"
                          }`}
                        >
                          {item.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    }
    if (activeTab === "accidents") {
      if (view === "pending") {
        return (
          <div className="bg-white text-xs lg:text-base rounded-lg p-4">
            <br />
            <div className="flex flex-col items-center lg:w-[1300px] ml-40 lg:ml-32">
              {/* Table */}
              <table className="w-full bg-white  rounded-lg mb-4 border-separate border-spacing-y-4">
                <thead className="bg-white shadow-md rounded-lg">
                  <tr>
                    <th className="px-4 py-2 text-left ">S.No</th>
                    <th className="px-4 py-2 text-center ">Date</th>
                    <th className="px-4 py-2 text-center">Area Name</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                    <th className="px-4 py-2 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingAccidents.map((item, index) => (
                    <tr
                      key={index}
                      className="transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)] bg-white rounded-lg"
                    >
                      <td className="px-4 py-2 text-left rounded-l-lg">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.accident_date}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.area_name}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.accident_address.split(",")[0]}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <img
                          src={item.accident_img_url}
                          alt="Accident"
                          onClick={() => openModal(item.accident_img_url)}
                          className="w-16 h-12 object-cover mx-auto rounded-lg"
                        />
                      </td>
                      <td className="px-4  py-2 text-center rounded-r-lg space-y-2">
                        <button
                          className="bg-[#00A8F0] text-white px-2 py-1 rounded"
                          onClick={() => acceptAccident(item.aid)}
                        >
                          Accept
                        </button>
                        <button
                          className="bg-[#F06960] text-white px-2 py-1 w-full lg:w-auto rounded lg:ml-2"
                          onClick={() => rejectAccident(item.aid)}
                        >
                          Deny
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      } else if (view === "reviewed") {
        return (
          <div className="bg-white text-xs lg:text-base rounded-lg p-3">
            <br />
            <div className="flex flex-col items-center lg:w-[1300px] ml-44 lg:ml-32">
              {/* Table */}
              <table className="w-full  bg-white  rounded-lg mb-4 border-separate border-spacing-y-4 p-2">
                <thead className="bg-white shadow-md rounded-lg">
                  <tr>
                    <th className="px-4 py-2 text-left">S.No</th>
                    <th className="px-4 py-2 text-center">Date</th>
                    <th className="px-4 py-2 text-center">Area Name</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                    <th className="px-4 py-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reviewedAccidents.map((item, index) => (
                    <tr
                      key={index}
                      className=" duration-300 transition-all overflow-hidden hover:scale-105 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]  shadow-lg bg-white rounded-lg"
                    >
                      <td className="px-4 py-2 rounded-l-lg text-left ">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.accident_date}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.area_name}
                      </td>

                      <td className="px-4 py-2  text-center">
                        {item.accident_address.split(",")[0]}
                      </td>
                      <td className="px-4 py-2  text-center">
                        <img
                          src={item.accident_img_url}
                          alt="Accident"
                          onClick={() => openModal(item.accident_img_url)}
                          className="w-16 h-12 object-cover mx-auto rounded-md cursor-pointer"
                        />
                      </td>
                      <td className="px-4 py-2 rounded-r-lg text-center">
                        <button
                          className={`px-2 py-1 rounded ${
                            item.status === "accepted"
                              ? "bg-[#00A8F0] text-white"
                              : "bg-[#F06960] text-white"
                          }`}
                        >
                          {item.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    }

    if (activeTab === "traffic") {
      if (view === "pending") {
        return (
          <div className="bg-white text-xs lg:text-base rounded-lg p-4">
            <br />
            <div className="flex flex-col items-center lg:w-[1300px] ml-40  lg:ml-32">
              {/* Table */}
              <table className="w-full bg-white  rounded-lg mb-4 border-separate border-spacing-y-4">
                <thead className="bg-white shadow-md rounded-lg">
                  <tr>
                    <th className="px-4 py-2 text-left ">S.No</th>
                    <th className="px-4 py-2 text-center">Date</th>
                    <th className="px-4 py-2 text-center">Area Name</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                    <th className="px-4 py-2 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingTrafficJams.map((item, index) => (
                    <tr
                      key={index}
                      className="transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)] bg-white rounded-lg"
                    >
                      <td className="px-4 py-2 text-left rounded-l-lg">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.jam_date}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.jam_areaname}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {item.jam_address.split(",")[0]}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <img
                          src={item.trafficjam_img_url}
                          alt="Violation"
                          onClick={() => openModal(item.trafficjam_img_url)}
                          className="w-16 h-12 object-cover mx-auto rounded-lg"
                        />
                      </td>
                      <td className="px-4  py-2 text-center rounded-r-lg space-y-2">
                        <button
                          className="bg-[#00A8F0] text-white px-2 py-1 rounded"
                          onClick={() => acceptTrafficJam(item.jid)}
                        >
                          Accept
                        </button>
                        <button
                          className="bg-[#F06960] text-white px-2 py-1 lg:w-auto w-full rounded lg:ml-2"
                          onClick={() => rejectTrafficJam(item.jid)}
                        >
                          Deny
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      } else if (view === "reviewed") {
        return (
          <div className="bg-white text-xs lg:w-[1452px] lg:text-base rounded-lg lg:p-3">
            <br />
            <div className="flex flex-col items-center ml-44  lg:ml-32">
              {/* Table */}
              <table className="w-full  bg-white  rounded-lg mb-4 border-separate border-spacing-y-4 p-2">
                <thead className="bg-white shadow-md rounded-lg">
                  <tr>
                    <th className="px-4 py-2 text-left">S.No</th>
                    <th className="px-4 py-2 text-center">Date</th>
                    <th className="px-4 py-2 text-center">Area Name</th>
                    <th className="px-4 py-2 text-center">Address</th>
                    <th className="px-4 py-2 text-center">Image</th>
                    <th className="px-4 py-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reviewedTrafficJams.map((item, index) => (
                    <tr
                      key={index}
                      className=" duration-300 transition-all overflow-hidden hover:scale-105 hover:shadow-[0px_8px_19px_rgba(223,223,253,0.6)]  shadow-lg bg-white rounded-lg"
                    >
                      <td className="px-4 py-2 rounded-l-lg text-left ">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.jam_date}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.jam_areaname}
                      </td>
                      <td className="px-4 py-2  text-center">
                        {item.jam_address.split(",")[0]}
                      </td>
                      <td className="px-4 py-2  text-center">
                        <img
                          src={item.trafficjam_img_url}
                          alt="Violation"
                          onClick={() => openModal(item.trafficjam_img_url)}
                          className="lg:w-16 lg:h-12  h-12 object-cover mx-auto rounded-md cursor-pointer"
                        />
                      </td>
                      <td className="px-4 py-2 rounded-r-lg text-center">
                        <button
                          className={`px-2 py-1 rounded ${
                            item.status === "accepted"
                              ? "bg-[#00A8F0] text-white"
                              : "bg-[#F06960] text-white"
                          }`}
                        >
                          {item.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    }

    // Add cases for other tabs if needed
  };

  return (
    <div className="p-4">
      <h1
        className="text-2xl font-bold mb-10"
        style={{ fontSize: "35px", fontWeight: "bold" }}
      >
        RevRoad
      </h1>
      <a href="/logout" className="text-right text-blue-500">
        Logout
      </a>
      <h1
        className="text-2xl font-bold mb-10 text-indigo-950 lg:ml-52"
        style={{ fontSize: "35px", fontFamily: "Poppins", fontWeight: "bold" }}
      >
        Reviewer Home
      </h1>
      <div className="mb-4 flex flex-wrap justify-center lg:space-x-36 space-y-6 lg:space-y-0">
        <button
          className={` font-bold px-4 py-2 text-xl transition-all duration-500 hover:shadow-lg hover:scale-105 border-slate-200  rounded-xl border-2 lg:ml-52  w-80 h-32 ${
            activeTab === "violations"
              ? "bg-[#00ADF8] text-white"
              : "bg-white-200 text-black"
          }`}
          onClick={() => handleTabClick("violations")}
        >
          Violations
        </button>
        <button
          className={` font-bold px-4 py-2 transition-all duration-500 hover:shadow-lg hover:scale-105  text-xl border-slate-200 border-2 rounded-xl  w-80 h-32 ${
            activeTab === "accidents"
              ? "bg-[#00ADF8] text-white"
              : "bg-white-200 text-black "
          }`}
          onClick={() => handleTabClick("accidents")}
        >
          Accidents
        </button>
        <button
          className={` font-bold px-4 py-2 text-xl border-slate-200 border-2 transition-all duration-500 hover:shadow-lg hover:scale-105  rounded-xl w-80 h-32 ${
            activeTab === "traffic"
              ? "bg-[#00ADF8] text-white"
              : "bg-white-200 text-black"
          }`}
          onClick={() => handleTabClick("traffic")}
        >
          Traffic
        </button>
      </div>
      <br /> <br />
      {(activeTab === "violations" ||
        activeTab === "accidents" ||
        activeTab === "traffic") && (
        <div className="mb-4 flex space-x-4  justify-center lg:justify-start lg:ml-52">
          <button
            className={` font-bold w-24 border-none flex px-3 py-1.5   text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer ${
              view === "pending"
                ? "bg-[#0A2BD9] text-white"
                : "bg-gray-200 text-black"
            }`}
            onClick={() => handleViewClick("pending")}
          >
            Pending
          </button>
          <button
            className={` font-bold  w-24 ml-52 border-none flex px-3 py-1.5   text-[15px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer  ${
              view === "reviewed"
                ? "bg-[#0A2BD9]  text-white"
                : "bg-gray-200 text-black"
            }`}
            onClick={() => handleViewClick("reviewed")}
          >
            Reviewed
          </button>
        </div>
      )}
      <div className="overflow-x-auto">{renderTableContent()}</div>
      {isModalOpen && <Modal imageSrc={modalImage} onClose={closeModal} />}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default ReviewerHome;
