// src/SignUpForm.js

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

// import './Signup.css';
import '../css/Login.css'
import LoginNav from './LoginNav';

const SignUpForm = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const nameRegex = /^[a-zA-Z\s-]{2,}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const location = useLocation();
  const { phoneNumber } = location.state || {};
  const navigate = useNavigate();

  const handleSubmit=async (e)=>{
    e.preventDefault();
    if (!nameRegex.test(name)){
      toast.error("Invalid Name");
      return
    }
    if (email !== ""){
      if(!emailRegex.test(email)){
        toast.error("Invalid Email")
        return
      }
    }
    try{
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/auth/create-user?role=user',{
      "mobile_number":phoneNumber,
      "name":name,
      "email":email
    })
    if(response.status === 200){
      if (response.data.access_token){
        localStorage.setItem("token",response.data.access_token)
        navigate('/')
      }
      else{
        toast.error("Unable to create account. Please Try again Later.")
        navigate('/login')
      }
    }
  }
  catch(err){
    if(!err){
      toast.error("Unable to create account. Please Try again Later.")
    }
    if (err.response.status === 400) {
      toast.error("Data Missing.");
  } else if (err.response.status === 500) {
      toast.error("Internal Server Error");
  }
  if (err.response) {
    if (err.response.status === 401) {
      toast.error("Unable to fetch details. Please Login Again.");
      navigate('/logout');
    }
    if(err.response.status === 403){
      toast.error("Unable to fetch details. Please Login Again.");
      navigate('/logout');
    }
  }
  if (err.request) {
    toast.error("Unable to fetch details. Please try again later.");
  }
  } 
  }

  return (
    <div>
    {/* <nav className="navbar">
      <div className="rev">
    <a href="/">
      RevRoad
    </a>
    </div>
    <a className="about" href="/contact">
      About us
    </a>
  </nav> */}
    <LoginNav/>
    <div className="main">
      <div className="form1"></div>
      <div className="form">
        <h3 className='lo'>Sign Up:</h3>
        <form onSubmit={handleSubmit} className='form-block'  >
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" className='input-field' value={name} onChange={(e)=>{setname(e.target.value)}} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email (Optional)  :</label>
            <input type="email" id="email" name="email" className='input-field' value={email} onChange={(e)=>{setemail(e.target.value)}} />
          </div>
          <button  className='btn' type='submit'>Submit</button>
        </form>
      </div>
    </div>
    <ToastContainer position='bottom-right' />
    </div>
  );
}

export default SignUpForm;
