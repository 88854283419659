import React, { useState } from "react";
import "../../css/WavedChallans.css";
import Navbar from "../Navbar";
import Image from "../../../assets/Image.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { RiCloseCircleLine } from "react-icons/ri";
const App = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [challan_rem, setChallan_rem] = useState(0);

    //Image Modal
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
  
    const openModal = (imageSrc) => {
      setModalImage(imageSrc);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setModalImage("");
    };
  
    const Modal = ({ imageSrc, onClose }) => (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="relative">
          <img
            src={imageSrc}
            alt="Enlarged Violation"
            className="max-w-screen max-h-screen rounded-xl"
          />
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-2 text-white   bg-black bg-opacity-50 px-2 py-1 rounded-lg"
          >
            <RiCloseCircleLine className="text-3xl"/>
          </button>
        </div>
      </div>
    );

  const handleWaive = () => {
    setShowConfirmation(true);
  };

  const confirmWaive = () => {
    setShowSummary(true);
    setShowConfirmation(false);
    const totalChallanAmount = data
      .filter((item) => selectedIds.includes(item.cuid))
      .reduce((acc, item) => acc + item.fine, 0);
    setChallan_rem(totalChallanAmount);
  };

  const handleProceed = async () => {
    try {
      const payload = {
        vehicle_number: searchTerm,
        cuid_list: selectedIds,
        challan_rem: parseInt(challan_rem),
      };
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/api/commissioner/waive-off-challans",
        JSON.stringify(payload),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Challan waived successfully");
        setData(data.filter((item) => !selectedIds.includes(item.cuid)));
        setSelectedIds([]);
        setShowSummary(false);
      } else {
        toast.error("Error fetching data");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      } else if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  };

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
    fetchData(e.target.value);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(data.map((item) => item.cuid));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((item) => item !== id)
        : [...prevSelectedIds, id]
    );
  };

  const fetchData = async (vehicleNumber) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `/api/commissioner/get-vehicle-challans?vehicle_number=${vehicleNumber}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setData(response.data.challans_history);
        setNoResults(response.data.challans_history.length === 0);
      } else {
        toast.error("Error fetching data");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      } else if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
        window.location.reload();
      }
    }
  };

  return (
    <div className="container">
      <Navbar />
      <div className="Comissioner-home mt-12">
        <h1>Commissioner</h1>
        <div className="relative flex items-center gap-2 justify-start w-20 rounded-md p-2 mb-6 shadow-md lg:mx-12">
          <button
            className="flex items-center gap-2"
            onClick={() => navigate(-1)}
          >
            <IoArrowBack className=" text-gray-500 " />
            Back
          </button>
        </div>
        <h2 className="text-4xl text-[#002366] mt-7 font-bold lg:ml-[10%] mb-3">
          Waive Challan
        </h2>

        {/* <a href="/logout">
          <h6
            className="text-2xl font-bold lg:mb-10 text-indigo-950 lg:ml-24"
            style={{
              fontSize: "25px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Logout
          </h6>
        </a> */}
        <div className="flex flex-wrap mb-6 lg:gap-20 lg:mr-6 lg:justify-end">
          <div className="flex gap-2">
            <div className="border-2 flex rounded-2xl px-2 py-2">
              <span>🔍</span>
              <input
                className="ml-2 outline-none"
                type="text"
                placeholder="Search vehicle no."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded-lg" onClick={() => fetchData(searchTerm)}>
              Search
            </button>
          </div>

          <button
            className="px-4 py-2 rounded-lg mt-2 lg:mt-0 text-white font-semibold bg-red-600 hover:bg-red-700 cursor-pointer"
            onClick={handleWaive}
            disabled={selectedIds.length === 0}
          >
            Waive
          </button>
        </div>
        {noResults && <p className="text-red-500">No vehicles found.</p>}
        <div className="overflow-x-auto border text-sm lg:text-base rounded-2xl mt-[4%]">
          <table className="waivetable">
            <thead className="bg-gray-100">
              <tr>
                <th>
                  <input
                    className="h-5 w-5"
                    type="checkbox"
                    onChange={handleSelectAll}
                  />
                </th>
                <th>S.NO</th>
                <th>Date</th>
                <th>Vehicle.No</th>
                <th>Area</th>
                <th>Image</th>
                <th>Challan Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={item.cuid}
                  className={selectedIds.includes(item.cuid) ? "selected" : ""}
                >
                  <td>
                    <input
                      className="h-5 w-5"
                      type="checkbox"
                      checked={selectedIds.includes(item.cuid)}
                      onChange={() => handleSelect(item.cuid)}
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td>{item.timestamp.split("T")[0]}</td>
                  <td>{searchTerm.toUpperCase()}</td>
                  <td>
                    {item.address === null ? "-" : item.address.split(",")[0]}
                  </td>
                  <td>
                    <img
                      src={item.img_url || Image}
                      alt="vehicle"
                      onClick={() => openModal(item.img_url)}
                      style={{ width: "50px", height: "30px" }}
                      className="cursor-pointer rounded-md"
                    />
                  </td>
                  <td>Rs.{item.fine}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showConfirmation && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Confirm Waive</h2>
            <p>Do you want to waive the selected challan(s)?</p>
            <div className="font-semibold w-[60%] flex justify-between ml-[20%] mt-12">
              <button
                className="h-[5vh] w-[30%] rounded-xl bg-[#1BCAF0] text-white hover:bg-[#1BCAF0] outline-none"
                onClick={confirmWaive}
              >
                Yes
              </button>
              <button
                className="h-[5vh] w-[30%] rounded-xl bg-grey border-2 hover:bg-white text-black"
                onClick={() => setShowConfirmation(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {showSummary && (
        <div className="popup">
          <div className="popup-inner">
            <p className="text-lg text-left font-semibold mb-6 mt-3">
              Number of vehicles selected: {selectedIds.length}
            </p>
            <p className="text-lg text-left font-semibold mb-6 mt-3">
              Total challan amount for selected Vehicle{" "}
            </p>
            <p className="text-lg font-bold mb-6 mt-3">Rs. {challan_rem}</p>
            <div className="flex justify-start">
              <button
                className="h-[4.5vh] w-[45%] rounded-2xl text-white bg-[#1BCAF0] outline-none ml-[80%]"
                onClick={handleProceed}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
       {isModalOpen && <Modal imageSrc={modalImage} onClose={closeModal} />}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default App;
