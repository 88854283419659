import React, { useEffect, useState } from "react";
import imageIcon from "../../assets/Image.png";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { RiCloseCircleLine } from "react-icons/ri";

function History() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const { reportHistory } = location.state || {};


    //Image Modal
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
  
    const openModal = (imageSrc) => {
      setModalImage(imageSrc);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setModalImage("");
    };
  
    const Modal = ({ imageSrc, onClose }) => (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="relative">
          <img
            src={imageSrc}
            alt="Enlarged Violation"
            className="max-w-screen max-h-screen rounded-xl"
          />
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-2 text-white   bg-black bg-opacity-50 px-2 py-1 rounded-lg"
          >
            <RiCloseCircleLine className="text-3xl"/>
          </button>
        </div>
      </div>
    );

  useEffect(() => {
    if (!reportHistory) {
      const token = localStorage.getItem("token");
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/api/common/get-violations", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          setdata(response.data);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              toast.error("Unable to fetch details. Please Login Again.");
              navigate("/logout");
            }
            if (err.response.status === 403) {
              toast.error("Unable to fetch details. Please Login Again.");
              navigate("/logout");
            }
          }
          if (err.request) {
            toast.error("Unable to fetch details. Please try again later.");
          }
        });
    } else {
      setdata(reportHistory);
    }
  }, []);
  return (
    <>
      <Navbar />
      <Sidebar />
      <main className="mt-12 lg:mt-20 ml-20 md:ml-20 lg:ml-32 xl:ml-72">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
          Report History
        </h1>

        <div className="rounded-xl mr-4 mt-12 shadow-xl hover:bg-gray-200 bg-gray-100 mb-12 transition duration-300 p-1 lg:p-6">
          <div className="flex justify-between pb-4">
            <h3 className="text-2xl font-semibold">Reports</h3>
          </div>
          <hr className="mb-6 border-0 h-0.5 bg-gray-400" />
          {data.length > 0 ? (
            <div className="min-h-[60vh] overflow-x-auto rounded-md text-xs lg:text-sm">
              <table className="table-auto w-full">
                <thead className="border-b-2 lg:border-b-4  border-gray-500">
                  <tr>
                    <th className="px-4 py-2 text-left">Vehicle No</th>
                    <th className="px-4 py-2 text-center">Violation</th>
                    <th className="px-4 py-2 text-center">Date</th>
                    <th className="px-4 py-2 text-center">Image</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((violation, index) => (
                    <tr className="border-b" key={index}>
                      <td className="px-4 py-2 text-left">{violation.vehicle_number}</td>
                      <td className="px-4 py-2 text-center">
                        {violation.violation_type}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {violation.violation_date}
                      </td>
                      <td className="px-4 py-2 flex justify-center">
                        <img
                          src={violation.violation_img_url || imageIcon}
                          alt=""
                          onClick={() => openModal(violation.violation_img_url)}
                          className="max-h-10 rounded-md"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="h-[300px] flex justify-center items-center">
              <span className="font-bold">oh!!</span> You have no submitted
              Reports
            </div>
          )}
        </div>
      </main>
      {isModalOpen && <Modal imageSrc={modalImage} onClose={closeModal} />}
      <ToastContainer position="top-right" />
    </>
  );
}

export default History;
