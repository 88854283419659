import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../css/Filter.css';

const districtOptions = [
  { value: 'adilabad', label: 'Adilabad' },
  { value: 'bhadradriKothagudem', label: 'Bhadradri Kothagudem' },
  { value: 'hyderabad', label: 'Hyderabad' },
  { value: 'jagtial', label: 'Jagtial' },
  { value: 'jangaon', label: 'Jangaon' },
  { value: 'jayashankarBhupalpally', label: 'Jayashankar Bhupalpally' },
  { value: 'jogulambaGadwal', label: 'Jogulamba Gadwal' },
  { value: 'kamareddy', label: 'Kamareddy' },
  { value: 'karimnagar', label: 'Karimnagar' },
  { value: 'khammam', label: 'Khammam' },
  { value: 'komaramBheem', label: 'Komaram Bheem' },
  { value: 'mahabubabad', label: 'Mahabubabad' },
  { value: 'mahabubnagar', label: 'Mahabubnagar' },
  { value: 'mancherial', label: 'Mancherial' },
  { value: 'medak', label: 'Medak' },
  { value: 'medchalMalkajgiri', label: 'Medchal Malkajgiri' },
  { value: 'mulugu', label: 'Mulugu' },
  { value: 'nagarkurnool', label: 'Nagarkurnool' },
  { value: 'nalgonda', label: 'Nalgonda' },
  { value: 'narayanpet', label: 'Narayanpet' },
  { value: 'nirmal', label: 'Nirmal' },
  { value: 'nizamabad', label: 'Nizamabad' },
  { value: 'peddapalli', label: 'Peddapalli' },
  { value: 'rajannaSircilla', label: 'Rajanna Sircilla' },
  { value: 'rangareddy', label: 'Rangareddy' },
  { value: 'sangareddy', label: 'Sangareddy' },
  { value: 'siddipet', label: 'Siddipet' },
  { value: 'suryapet', label: 'Suryapet' },
  { value: 'vikarabad', label: 'Vikarabad' },
  { value: 'wanaparthy', label: 'Wanaparthy' },
  { value: 'warangalRural', label: 'Warangal Rural' },
  { value: 'warangalUrban', label: 'Warangal Urban' },
  { value: 'yadadriBhuvanagiri', label: 'Yadadri Bhuvanagiri' },
];

const Filters = ({ title, selectedDistricts, handleDistrictChange, selectedDate, handleDateChange }) => {
  return (
    <>
      <div className="heading">
        <h1>{title}</h1>
      </div>
      <div className="overall-filters-container">
        <Select
          isMulti
          name="districts"
          options={districtOptions}
          className="overall-basic-multi-select"
          classNamePrefix="select"
          placeholder="Select Districts"
          value={selectedDistricts}
          onChange={handleDistrictChange}
          isSearchable
        />
        <div className="datepicker-container">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange} // Ensure this function is passed
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Date"
            className='datepicker-container-field'
            maxDate={new Date()} // Restricts future dates
          />
        </div>
      </div>
    </>
  );
};

export default Filters;
