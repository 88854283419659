import React from "react";
import revroad from "../../assets/revroad.svg";
import "../css/LoginNav.css";

function LoginNav() {
  return (
    <div>
      <div className="nav">
        <h2 className="text-xl ml-8 md:text-2xl">
          <a href="/" className="flex items-center gap-2">
            <img src={revroad} alt="Logo" className="h-8"  />
            RevRoad
          </a>
        </h2>
        <a href="/About" className="mr-8 md:text-1xl">
          About Us
        </a>
      </div>
    </div>
  );
}

export default LoginNav;
