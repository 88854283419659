import { IoIosAddCircleOutline } from "react-icons/io";
import { PiAmbulanceFill } from "react-icons/pi";
import { ImHome } from "react-icons/im";
import {
  FaCarCrash,
  FaTrafficLight,
  FaExclamationTriangle,
} from "react-icons/fa";
import GradingIcon from '@mui/icons-material/Grading';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AddCardIcon from '@mui/icons-material/AddCard';

const menu = [
  {
    title: "New Complaint",
    src: (
      <div className="bg-[#8B68F7] rounded-full">
        <IoIosAddCircleOutline className={`text-3xl text-white`} />
      </div>
    ),
    submenu: [
      {
        title: "File a Complaint",
        href: "/dashboard/report/violation",
        icon: <FaExclamationTriangle className="text-lg" />,
      },
      {
        title: "Accident",
        href: "/dashboard/report/accident",
        icon: <FaCarCrash className="text-lg" />,
      },
      {
        title: "Traffic",
        href: "/dashboard/report/traffic",
        icon: <FaTrafficLight className="text-lg" />,
      },
    ],
  },
  {
    title: "Home",
    src: <ImHome className={`text-2xl ml-1`} />,
    gap: false,
    href: "/",
  },
  // {
  //   title: "Driving License",
  //   src: < GradingIcon  className={`text-4xl ml-1`} />,
  //   href: "/dashboard/drivinglincense",
  // },
  
  {
    title: "Points",
    src: < EmojiEventsIcon className={`text-4xl ml-1`} />,
    href: "/dashboard/Points",
  },
  {
    title: "Add Vehicle",
    src: < DeliveryDiningIcon  className={`text-4xl ml-1`} />,
    href: "/dashboard/Addvehicle",
  },
  {
    title: "Add License",
    src: < AddCardIcon  className={`text-4xl ml-1`} />,
    href: "/dashboard/AddYourLicense",
  },
 
];


if(localStorage.getItem('role')==='officer'){
  menu.push( {
    title: "Inspection",
    src: < ChecklistIcon  className={`text-4xl ml-1`} />,
    href: "/dashboard/Inspection",
  },)
}

export const Menus = menu;