import './App.css';
import Home from './components/js/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Report_Accident from './components/js/Report_Accident'; 
import Report_Traffic from './components/js/Report_Traffic';
import Violation from './components/js/ReportViolation';
import Login from './components/js/Login';
import Logout from './components/js/Logout';
import Signup from './components/js/Signup';
import DrivingLicense from './components/js/DrivingLicense';
import ReportHistory from './components/js/ReportHistory';
import AccidentHistory from './components/js/AccidentHistory';
import TrafficJamHistory from './components/js/TrafficJamHistory';
import PrivateRoute from './components/js/PrivateRoute';
import Profile from './components/js/Proflie'
import YourVehicles from './components/js/YourVehicles';
import AddYourLicense from './components/js/AddYourLicense';
import Points from './components/js/Points';
import Challan from './components/js/Challan';
import YourLicense from './components/js/YourLicense'; 
import ReviewerHome from './components/js/ReviewerHome';
import YourReports from './components/js/YourReports';
import AddVehicle from './components/js/AddVehicle';
import Officer from './components/js/Officer/Officer';
import OfficerMonthly from './components/js/Officer/OfficerMonthly';
// import OfficerInspection from './components/js/Oficer/OficerInspection';
import CommissionerHome from './components/js/commissioner/commissionerHome'
import TopPerformers from './components/js/commissioner/TopPerfomers';
import WavedChallans from './components/js/commissioner/WavedChallan';
function App() {
  return (
    <>
      <Router>
        <Routes>  
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/signup' element={<Signup />} />
          <Route path="/" 
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
           />   

           
          <Route 
            path="/dashboard/report/violation" 
            element={
              <PrivateRoute>
                <Violation />
              </PrivateRoute>
            } 
          /> 
          <Route 
            path="/dashboard/report/accident" 
            element={
              <PrivateRoute>
                <Report_Accident />
              </PrivateRoute>
            } 
          /> 
          <Route 
            path="/dashboard/report/traffic" 
            element={
              <PrivateRoute>
                <Report_Traffic />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/drivinglincense" 
            element={
              <PrivateRoute>
                <DrivingLicense />
              </PrivateRoute>
            } 
          /> 
          <Route 
            path="/dashboard/AddYourLicense" 
            element={
              <PrivateRoute>
                <AddYourLicense />
              </PrivateRoute>
            } 
          />  
           <Route 
            path="/dashboard/reviewerHome" 
            element={
              <PrivateRoute>
              <ReviewerHome />
              </PrivateRoute>
            } 
          /> 
          <Route 
            path="/dashboard/reportHistory" 
            element={
              <PrivateRoute>
                <ReportHistory />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/accidentHistory" 
            element={
              <PrivateRoute>
                <AccidentHistory />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/trafficHistory" 
            element={
              <PrivateRoute>
                <TrafficJamHistory />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/yourVehicles" 
            element={
              <PrivateRoute>
                <YourVehicles />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/profile" 
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/AddVehicle" 
            element={
              <PrivateRoute>
                <AddVehicle/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/Points" 
            element={
              <PrivateRoute>
                <Points/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/Challan" 
            element={
              <PrivateRoute>
                <Challan/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/YourLicense" 
            element={
              <PrivateRoute>
                <YourLicense/>
              </PrivateRoute>
            } 
          />
         <Route 
            path="/Officer/dashboard/home" 
            element={
              <PrivateRoute>
                <Officer/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/Officer/dashboard/month" 
            element={
              <PrivateRoute>
                <OfficerMonthly/>
              </PrivateRoute>
            } 
          />
          {/* <Route 
            path="/Officer/dashboard/Track-inspection" 
            element={
              <PrivateRoute>
                <OfficerInspection/>
              </PrivateRoute>
            } 
          /> */}
          <Route 
            path="/dashboard/YourReports" 
            element={
              <PrivateRoute>
                <YourReports/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/commissioner/dashboard/home" 
            element={
              <PrivateRoute>
                <CommissionerHome/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/commissioner/dashboard/topperformers" 
            element={
              <PrivateRoute>
                <TopPerformers/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/commissioner/dashboard/wavedchallans" 
            element={
              <PrivateRoute>
                <WavedChallans/>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/reviewer" 
            element={
              <PrivateRoute>
                <ReviewerHome/>
              </PrivateRoute>
            } 
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;