import React, { useState, useRef, useEffect } from "react";
import { Button, Avatar } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import '../css/Profile.css';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [mobile, setMobile] = useState(localStorage.getItem('mobile_number') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || 'Set New Email');
  const [profileImage, setProfileImage] = useState(localStorage.getItem('profile_url'));
  const [profileimg, setProfileimg] = useState(null);
  const [isEditing, setIsEditing] = useState({
    name: false,
    mobile: false,
    email: false,
  });

  const nameRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    if (isEditing.name) {
      nameRef.current.focus();
      nameRef.current.select();
    } else if (isEditing.mobile) {
      mobileRef.current.focus();
      mobileRef.current.select();
    } else if (isEditing.email) {
      emailRef.current.focus();
      emailRef.current.select();
    }
  }, [isEditing]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileimg(file);
    setProfileImage(URL.createObjectURL(file));
  };

  const handleEditClick = (field) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleBlur = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        name: name,
        mobile_number: mobile,
        email: email,
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/update-user`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        toast.success('Profile updated successfully');
        localStorage.setItem('name', response.data.name);
        localStorage.setItem('mobile_number', response.data.mobile_number);
        localStorage.setItem('email', response.data.email);
        navigate('/')
      } else {
        toast.error('No response from server. Please try again later.');
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          toast.error('Unable to fetch details. Please Login Again.');
          navigate('/logout');
        }
      } else if (err.request) {
        toast.error('Unable to fetch details. Please try again later.');
      }
    }
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('profile_img', profileimg);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/update-profile-img`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        toast.success('Profile image updated successfully');
        setProfileImage(response.data.profile_picture_url);
        localStorage.setItem('profile_url', response.data.profile_picture_url);
        window.location.reload();
      } else {
        toast.error('No response from server. Please try again later.');
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          toast.error('Unable to fetch details. Please Login Again.');
          navigate('/logout');
        }
      } else if (err.request) {
        toast.error('Unable to fetch details. Please try again later.');
      }
    }
  };

  const handleImageRemove = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/remove-profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        toast.success('Profile image deleted successfully');
        setProfileImage(response.data.profile_picture_url);
        localStorage.setItem('profile_url', response.data.profile_picture_url);
        window.location.reload();
      } else {
        toast.error('No response from server. Please try again later.');
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          toast.error('Unable to fetch details. Please Login Again.');
          navigate('/logout');
        }
      } else if (err.request) {
        toast.error('Unable to fetch details. Please try again later.');
      }
    }
  };
  return (
    <>
    {
      localStorage.getItem('role') === 'user' ? <Sidebar /> : null
    }
      <Navbar />
      <div className="main-profile">
        <div className="title">
          <h1 className="text-5xl"> Edit Profile</h1>
        </div>
        <form className="form-block-profile" onSubmit={handleSubmit}>
          <div className="form-left">
            <div className="input-block">
              <div className="input-flex">
                <div className="input-left">
                  <label htmlFor="name">Name :</label><br />
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    readOnly={!isEditing.name}
                    onBlur={() => handleBlur('name')}
                    className={`textfield-box ${isEditing.name ? 'highlighted' : ''}`}
                    ref={nameRef}
                  />
                </div>
                <div className="input-right">
                  <button
                    type="button"
                    className="btn-profile"
                    onClick={() => handleEditClick('name')}
                  >
                    <EditIcon className="icon" /> Edit
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <div className="input-block">
              <div className="input-flex">
                <div className="input-left">
                  <label htmlFor="mobile">Mobile number :</label><br />
                  <input
                    type="text"
                    name="mobile"
                    id="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    readOnly={!isEditing.mobile}
                    onBlur={() => handleBlur('mobile')}
                    className={`textfield-box ${isEditing.mobile ? 'highlighted' : ''}`}
                    ref={mobileRef}
                    inputMode="numeric"
                  />
                </div>
                <div className="input-right">
                  <button
                    type="button"
                    className="btn-profile"
                    onClick={() => handleEditClick('mobile')}
                  >
                    <EditIcon className="icon" /> Edit
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <div className="input-block">
              <div className="input-flex">
                <div className="input-left">
                  <label htmlFor="email">Email :</label><br />
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly={!isEditing.email}
                    onBlur={() => handleBlur('email')}
                    className={`textfield-box ${isEditing.email ? 'highlighted' : ''}`}
                    ref={emailRef}
                  />
                </div>
                <div className="input-right">
                  <button
                    type="button"
                    className="btn-profile"
                    onClick={() => handleEditClick('email')}
                  >
                    <EditIcon className="icon" /> Edit
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: "2%", backgroundColor: "#9879F7", width: "30%", height: "6vh", borderRadius: "50px" }}
              className="btn2"
            >
              Submit
            </Button>
          </div>
          <div className="form-right">
            <div className="image-container">
              {profileImage ? (
                <div className="img">
                  <img src={profileImage} alt="profile" className="profile-img" />
                </div>
              ) : (
                <Avatar sx={{ width: 150, height: 150 }}>
                  <AddPhotoAlternateIcon fontSize="large" />
                </Avatar>
              )}
              <Button
                variant="outlined"
                component="label"
                startIcon={<AddPhotoAlternateIcon />}
                sx={{ marginTop: "10px" }}
              >
                Add picture
                <input
                  type="file"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
              <br />
              <Button
                variant="outlined"
                component="label"
                // startIcon={<AddPhotoAlternateIcon />}
                sx={{ marginTop: "10px" }}
                onClick={handleImageRemove}
              >
                Remove profile
              </Button>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: "2%", backgroundColor: "#9879F7", width: "30%", height: "6vh", borderRadius: "50px" }}
              className="btn2"
              onClick={handleImageSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
      <ToastContainer position='top-right' />
    </>
  );
};

export default ProfilePage;
