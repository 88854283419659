import React, { useEffect, useState } from "react";
import "../css/Points.css";
import Navbar from "./Navbar";
import axios from "axios";
import Sidebar from "./Sidebar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Points = () => {
  const [violationData, setViolationData] = useState([]);
  const [trafficData, setTrafficData] = useState([]);
  const [accidentData, setAccidentData] = useState([]);
  const [points, setPoints] = useState(0); // Initialize points with 0
  const navigate = useNavigate();

  async function fetchData() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/common/get-points-data",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        const data = response.data;
        setPoints(data.points);

        const violationHistory = data.points_history.filter(
          (history) => history.purpose === "Violation"
        );
        const trafficHistory = data.points_history.filter(
          (history) => history.purpose === "Traffic jam"
        );
        const accidentHistory = data.points_history.filter(
          (history) => history.purpose === "Accident"
        );

        setViolationData(violationHistory);
        setTrafficData(trafficHistory);
        setAccidentData(accidentHistory);
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session has been expired. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [navigate]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="point-main">
        <div className="content">
          <div className="point-title">
            <h1 className="color po-title">Points History</h1>
            <div className="point-block">
              <h2>Total Points</h2>
              <h1>{points}</h1>
            </div>
          </div>
          <div className="point-tables">
            <div className="violation-table">
              <h3 className="color po-tt">Violation Reports</h3>
              <div className="table-points">
                {violationData.length === 0 ? (
                  <div className="no-reports-message">
                    You have zero points violation reports.
                  </div>
                ) : (
                  <table className="pointsTable">
                    <thead className="pointsThead">
                      <tr className="t-head">
                        <th>S.no</th>
                        <th>Date</th>
                        <th>Vehicle Number</th>
                        <th>Violation</th>
                        <th>Status</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {violationData.map((row, index) => (
                        <tr className="t-data" key={index}>
                          <td>{index + 1}</td>
                          <td>{row.info.violation_date}</td>
                          <td>{row.info.vehicle_number}</td>
                          <td>{row.info.violation_type}</td>
                          <td>{row.info.status}</td>
                          <td>{row.points}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="traffic-table">
              <h3 className="color po-tt">Traffic Reports</h3>
              <div className="table-points">
                {trafficData.length === 0 ? (
                  <div className="no-reports-message">
                    You have zero points on traffic jam reports.
                  </div>
                ) : (
                  <table className="pointsTable">
                    <thead className="pointsThead">
                      <tr className="t-head">
                        <th>S.no</th>
                        <th>Date</th>
                        <th>Area Name</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trafficData.map((row, index) => (
                        <tr className="t-data" key={index}>
                          <td>{index + 1}</td>
                          <td>{row.info.jam_date}</td>
                          <td>{row.info.jam_areaname}</td>
                          <td>{row.info.jam_address.split(",")[0]}</td>
                          <td>{row.info.status}</td>
                          <td>{row.points}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="accident-table">
              <h3 className="color po-tt">Accident Reports</h3>
              <div className="table-points">
                {accidentData.length === 0 ? (
                  <div className="no-reports-message">
                    You have zero points on accidenet reports.
                  </div>
                ) : (
                  <table className="pointsTable">
                    <thead className="pointsThead">
                      <tr className="t-head">
                        <th>S.no</th>
                        <th>Date</th>
                        <th>Area Name</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accidentData.map((row, index) => (
                        <tr className="t-data" key={index}>
                          <td>{index + 1}</td>
                          <td>{row.info.accident_date}</td>
                          <td>{row.info.area_name}</td>
                          <td>{row.info.accident_address.split(",")[0]}</td>
                          <td>{row.info.status}</td>
                          <td>{row.points}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Points;
