import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import Filters from './Filter';
// import '../grpahs_css/OverallChallan.css';

// Sample data
const sampleData = [
  { district: 'Khammam', date: '2024-04-17', amount: 20000, count: 50 },
  { district: 'Bhadradri Kothagudem', date: '2024-04-17', amount: 18000, count: 40 },
  { district: 'Hyd', date: '2024-04-18', amount: 20000, count: 20 },
  // Add more sample data as needed
];

// Telangana districts
const districtOptions = [
  { value: 'Adilabad', label: 'Adilabad' },
  { value: 'Bhadradri Kothagudem', label: 'Bhadradri Kothagudem' },
  { value: 'Hyderabad', label: 'Hyderabad' },
  // Add more districts as needed
];

const CitizenChallan = () => {
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDistrictChange = (selectedOptions) => {
    setSelectedDistricts(selectedOptions || []);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredData = sampleData.filter((item) => {
    const districtMatch = selectedDistricts.length === 0 || selectedDistricts.some(option => option.value === item.district);
    const dateMatch = !selectedDate || item.date === selectedDate.toISOString().split('T')[0];
    return districtMatch && dateMatch;
  });

  const option = {
    legend: {},
    tooltip: {},
    dataset: {
      dimensions: ['district', 'Total amount of challans created by Citizen', 'Total no.of challans created by Citizen'],
      source: filteredData.map(item => ({
        district: item.district,
        'Total amount of challans created by Citizen': item.amount,
        'Total no.of challans created by Citizen': item.count
      }))
    },
    xAxis: { type: 'category' },
    yAxis: [
      {
        type: 'value',
        name: 'Total amount of challans created by Citizen',
        position: 'left'
      },
      {
        type: 'value',
        name: 'Total no.of challans created by Citizen',
        position: 'right'
      }
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Total amount of challans created by Citizen',
        itemStyle: {
          color: '#b8d7ff' // Set the color for the first bar series
        }
      },
      {
        type: 'bar',
        yAxisIndex: 1,
        name: 'Total no.of challans created by Citizen',
        itemStyle: {
          color: '#7bb4fc' // Set the color for the second bar series
        }
      }
    ]
  };
  

  useEffect(() => {
    const myChart = echarts.init(document.getElementById('main'));

    myChart.setOption(option);

    // Clean up the chart instance
    return () => {
      myChart.dispose();
    };
  }, [filteredData]); // Dependency on filteredData ensures useEffect runs when data changes

  return (
    <>
      <div className="overall-main">
        <div className="overall-heading">
        </div>
        <Filters
          title="Citizen Challan"
          districtOptions={districtOptions}
          selectedDistricts={selectedDistricts}
          handleDistrictChange={handleDistrictChange}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}

        />
        <div className="graph">
          <div id="main" style={{ width: '100%', height: '400px' }}>
            <ReactECharts option={option} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CitizenChallan;
