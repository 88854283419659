import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import imageIcon from "../../assets/Image.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddYourLicense1() {
  const [licenseNumber, setLicenseNumber] = useState("");
  const [dob, setDob] = useState("");
  const [errors, setErrors] = useState({});
  const [licenseDetails, setLicenseDetails] = useState(null);
  const [detailsConfirmed, setDetailsConfirmed] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    let formErrors = {};
    if (!licenseNumber) formErrors.licenseNumber = "License number is required";
    if (!dob) formErrors.dob = "Date of birth is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const fetchDetails = async () => {
    if (!validateForm()) return;
    try{
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/users/get-license-info?license_number=${licenseNumber}&dob=${dob}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if(!response){
        toast.error("No response from server. Please try again later.");
      }
      if(response.status === 200){
        setLicenseDetails(response.data);
        setDetailsConfirmed(false);
      }
    }catch(err){
      if (err.request.status === 400) {
        toast.info("License Doesn't Exists.");
        return;
      }
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session Expired. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to Add License. Please try again later.");
      }
    }

    
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!detailsConfirmed) {
  //     alert("Please confirm the details before submitting");
  //     return;
  //   }

  //   // Submit the form
  //   console.log("Form submitted", { licenseNumber, dob, licenseDetails });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const isValid = validate();

    // if (!isValid) {
    //   toast.error("Please fill all the required fields.");
    //   return;
    // }

    try {
      const formData = new FormData();
      const data = {
        license_number: licenseDetails.license_number,
        expiry_date: licenseDetails.expiry_date,
        holder_name: licenseDetails.holder_name,
        father_name: licenseDetails.father_name,
        license_class: licenseDetails.license_class,
        transport_class: licenseDetails.transport_class,
        issue_date: licenseDetails.issue_date,
        license_img:licenseDetails.license_img,
        dob: dob,
      };
      formData.append("license_data", JSON.stringify(data));
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/api/users/add-license",
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (!response) {
        toast.error("NO response from server. Please try again later.");
      }
      if (response.status === 200) {
        toast.success("License Added Successfully");
        navigate('/dashboard/YourLicense')
      }
    } catch (err) {
      if (err.request.status === 400) {
        toast.info("License Already Exists.");
        return;
      }
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session Expired. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to Add Vehicle. Please try again later.");
      }
    }
  };

  const handleTryAgain = () => {
    setLicenseNumber("");
    setDob("");
    setErrors({});
    setLicenseDetails(null);
    setDetailsConfirmed(false);
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="ml-[69px] mt-12 lg:mt-20 lg:ml-64">
        <h1 className="text-3xl lg:ml-14 sm:text-4xl md:text-4xl font-bold text-[#181f6d]">
          Add License
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-64 lg:ml-24 w-[320px] lg:w-[900px] p-6">
            <div>
              <label htmlFor="licenseNumber" className="font-bold">
                License Number
              </label>
              <br />
              <input
                type="text"
                value={licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                maxLength={16}
                placeholder="Enter your license number"
                className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
              />
              {errors.licenseNumber && (
                <p className="text-red-500">{errors.licenseNumber}</p>
              )}
            </div>
            <div>
              <label htmlFor="dob" className="font-bold">
                Date of Birth
              </label>
              <br />
              <input
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
              />
              {errors.dob && <p className="text-red-500">{errors.dob}</p>}
            </div>
          </div>
          {!licenseDetails && (
            <button
              type="button"
              onClick={fetchDetails}
              className="mt-4 ml-[96px] lg:ml-[867px] p-2 bg-[#9476fb] text-white border-0 rounded-full py-1 px-3"
            >
              Get Details
            </button>
          )}
          {licenseDetails && (
            <div className="ml-6 lg:ml-[120px] bg-white rounded-md w-[273px] lg:w-full">
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-64 lg:w-[855px]">
                <div>
                  <div className="mb-6">
                    <label className="font-bold">Owner Name</label>
                    <br />
                    <input
                      type="text"
                      value={licenseDetails.holder_name}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="font-bold">Father Name</label>
                    <br />
                    <input
                      type="text"
                      value={licenseDetails.father_name}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="font-bold">License Class</label>
                    <br />
                    <input
                      type="text"
                      value={licenseDetails.license_class}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="font-bold">Transport Class</label>
                    <br />
                    <input
                      type="text"
                      value={licenseDetails.transport_class}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-6">
                    <label className="font-bold">Issued Date</label>
                    <br />
                    <input
                      type="text"
                      value={licenseDetails.issue_date}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="font-bold">Expiry Date</label>
                    <br />
                    <input
                      type="text"
                      value={licenseDetails.expiry_date}
                      readOnly
                      className="mt-1 p-2 w-full bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]"
                    />
                  </div>
                  <div className="mb-6">
                    <img
                      src={licenseDetails.license_img || imageIcon}
                      alt="License"
                      className="mt-2"
                    />
                  </div>
                  <label className="font-bold item-center ml-16 mb-12 lg:ml-20 mt-0 px-12 py-2 w-full lg:w-[130px] sm:w-auto bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000]">
                    License
                  </label>
                </div>
              </div>
              <div className="mt-8">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={detailsConfirmed}
                    onChange={(e) => setDetailsConfirmed(e.target.checked)}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="ml-2 mt-2">Confirm Details</span>
                </label>
                <Link
                  to="#"
                  onClick={handleTryAgain}
                  className="ml-4 text-blue-500 hover:underline"
                >
                  Try Again
                </Link>
              </div>
            </div>
          )}
          {licenseDetails && (
            <button
              type="submit"
              
              className="mt-4 mb-8 ml-[225px] lg:ml-[900px] p-2 bg-[#9476fb] text-white border-0 rounded-full py-1 px-3"
            >
              Submit
            </button>
          )}
        </form>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}

export default AddYourLicense1;
