import React, { useEffect, useState } from "react";
import imageIcon from "../../../assets/Image.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import '../../css/OfficerHistory.css'
import '../../css/OfficerComplaint.css'
import axios from "axios";
const OfficerHome = () => {

  const [activeTab, setActiveTab] = useState("inspect");
  const [view, setView] = useState("inspect");
  const [OfficerRewards, setOfficerRewards] = useState([])  //inte
  const [mobile_insp, setMobile_insp] = useState("")
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleViewClick = (view) => {
    setView(view);
  };

  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [drivingLicense, setDrivingLicense] = useState(false);
  const [pollution, setPollution] = useState(false);
  const [rcBook, setRcBook] = useState(false);



  const handleGetDetails = () => {
    if (!vehicleNumber) {
      toast.error('Please enter vehicle number!');
      return;
    }
    // Simulate data fetching
    const fetchedData = {
      registerName: "John Doe",
      insuranceStatus: "Active",
      drivingLicense: false,
      pollution: false,
      rcBook: false,
    };
    setVehicleDetails(fetchedData);
  };

  const handleInputChange = (e) => {
    setVehicleNumber(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setVehicleDetails({
      ...vehicleDetails,
      [name]: checked
    });
    switch (name) {
      case 'drivingLicense':
        setDrivingLicense(checked);
        break;
      case 'pollution':
        setPollution(checked);
        break;
      case 'rcBook':
        setRcBook(checked);
        break;
      default:
        break;
    }
  };

  // const handleSubmit = () => {
  //   // Submit data to API
  //   console.log("Submitted data:", vehicleDetails);
  // };

  const [rows, setRows] = useState([]);

  // State for validation messages
  const [validationMessages, setValidationMessages] = useState({});

  // Function to validate the rows
  const validateRows = () => {
    const messages = {};
    rows.inspection_history.forEach((row, index) => {
      if (!row.officerId) {
        messages[`officerId-${index}`] = "Officer ID is required";
      }
      if (!row.vehicleNumber) {
        messages[`vehicleNumber-${index}`] = "Vehicle Number is required";
      }
      if (!["YES", "NO"].includes(row.drivingLicense)) {
        messages[`drivingLicense-${index}`] =
          "Driving License must be YES or NO";
      }
      if (!["YES", "NO"].includes(row.pollution)) {
        messages[`pollution-${index}`] = "Pollution must be YES or NO";
      }
      if (!["YES", "NO"].includes(row.rcBook)) {
        messages[`rcBook-${index}`] = "RC Book must be YES or NO";
      }
      if (!["YES", "NO"].includes(row.status)) {
        messages[`status-${index}`] = "Status must be YES or NO";
      }
      if (typeof row.challan !== "number" || row.challan < 0) {
        messages[`challan-${index}`] = "Challan must be a non-negative number";
      }
    });
    setValidationMessages(messages);
  };

  // File a complaint component
  const [selectedImage, setSelectedImage] = useState(null);
  const [complaintVehicleNumber, setcomplaintVehicleNumber] = useState("");
  const [violationType, setViolationType] = useState("");
  const [location, setLocation] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [errors, setErrors] = useState({});
  const [violationList, setViolationList] = useState([])
  const [complaintVioImg, setcomplaintVioImg] = useState(null)

  const handleComplaintImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setcomplaintVioImg(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };



  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  // Reward component
  const [data, setData] = useState([]);
  const [totalInspections, setTotalInspections] = useState(0);
  const [totalChallanAmount, setTotalChallanAmount] = useState(0);
  const [selectedDay, setSelectedDay] = useState("Today");

  // for(let i=0;i<rows.inspection_history.length;i++){
  //   setTotalChallanAmount(totalChallanAmount+rows[i].challan);
  // }

  const dummyData = {
    inspections: [
      {
        officerId: "2634628",
        vehicleNumber: "TS4658AP",
        drivingLicense: true,
        pollution: true,
        rcBook: true,
        status: false,
        challan: 0,
      },
      {
        officerId: "2634628",
        vehicleNumber: "TS4658AP",
        drivingLicense: true,
        pollution: true,
        rcBook: false,
        status: false,
        challan: 500,
      },
      {
        officerId: "2634628",
        vehicleNumber: "TS4658AP",
        drivingLicense: true,
        pollution: true,
        rcBook: true,
        status: false,
        challan: 0,
      },
      {
        officerId: "2634628",
        vehicleNumber: "TS4658AP",
        drivingLicense: true,
        pollution: false,
        rcBook: false,
        status: false,
        challan: 1000,
      },
      {
        officerId: "2634628",
        vehicleNumber: "TS4658AP",
        drivingLicense: true,
        pollution: true,
        rcBook: true,
        status: false,
        challan: 0,
      },
    ],
    totalInspections: 10,
    totalChallanAmount: 10000,
  };
  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Simulate a delay
      setTimeout(() => {
        const { inspections, totalInspections, totalChallanAmount } = dummyData;
        setData(inspections);
        setTotalInspections(totalInspections);
        setTotalChallanAmount(totalChallanAmount);
      }, 1000); // 1 second delay
    };

    fetchData();
    // getOfficerRequests();
    getInspectionHistory();
    getLocation();
    fetchviolations();
  }, [selectedDay]);
  //for file a complaint page

  const getLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPlaceName(latitude, longitude);
        },
        (error) => {
          handleError(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,  // 10 seconds timeout
          maximumAge: 0    // No cached position
        }
      );
    } else {
      console.error('Geolocation is not available');
    }
  };

  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
      default:
        alert('An unexpected error occurred.');
        break;
    }
  };

  const getPlaceName = async (latitude, longitude) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
      const data = await response.json();
      if (data.display_name) {
        setLocation(`${data.display_name}`);
      } else {
        setLocation('Address not found');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setLocation('Error retrieving address.');
    }
  };

  const fetchviolations = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/common/get-options?doctype=violations');
      if (!response) {
        toast.error("Unable to connect. Please try again later.");
      }
      setViolationList(response.data);
    } catch (error) {
      toast.error("Failed to load violations.");
    }
  };
  const handleComplaintSubmit = async (event) => {
    console.log("Here")
    // event.preventDefault();
    let validationErrors = {};

    // Validate form data
    if (!vehicleNumber) {
      validationErrors.vehicleNumber = "Vehicle number is required";
    }
    if (!violationType) {
      validationErrors.violationType = "Violation type is required";
    }
    if (!location) {
      validationErrors.location = "Location is required";
    }
    if (!selectedImage) {
      validationErrors.selectedImage = "Image is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // return;
    }
    console.log("there")
    const violationData = {
      vehicle_number: complaintVehicleNumber,
      violation_type: violationType,
      violation_date: new Date().toISOString().split("T")[0],
      violation_address: location
    };
    const formData = new FormData();
    formData.append("violation_data", JSON.stringify(violationData));
    formData.append("violation_img", complaintVioImg);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/api/users/report-violation",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        toast.success("Violation Reported Successfully");
        setSelectedImage(null);
        setcomplaintVioImg(null)
        setVehicleNumber("");
        setViolationType("");
        setLocation("");
        setErrors({});
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          toast.error("Invalid File type.");
          return
        }
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  };


  // For Rewards section


  //Inspect vehicle page 
  const fetchDetailsforInspection = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/officer/get-vehicle-info?vehicle_number=${vehicleNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      if (!response) {
        toast.error("Unable to fetch details. Please try again later.");
        return;
      }
      if (response.status === 200) {
        console.log(response.data);
        setVehicleDetails(response.data);
      }
    } catch (err) {
      console.log(err)
      if (err.response) {
        if (err.response.status === 400) {
          toast.info("No Vehicle Found.");
          return
        }
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }

    }
  };

  const handleInspectionSubmit = async () => {
    try {
      const data = {
        vehicle_number: vehicleNumber,
        owner_name: vehicleDetails.owner_name,
        insurance_status: vehicleDetails.insurance_status,
        mobile: "+91" + mobile_insp,
        documents: [drivingLicense, pollution, rcBook]
      }
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/officer/inspect-vehicle", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      if (!response) {
        toast.error("Unable to fetch details. Please try again later.");
        return;
      }
      if (response.status === 200) {
        toast.success("Inspection details saved successfully.");
        setVehicleDetails([]);
        setVehicleNumber("");
        setDrivingLicense(false);
        setPollution(false);
        setRcBook(false);
        setMobile_insp("");
        getInspectionHistory();
      }
    } catch (err) {
      console.log(err)
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  };

  const getInspectionHistory = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/officer/get-inspection-history", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      if (!response) {
        toast.error("Unable to fetch details. Please try again later.");
        return;
      }
      if (response.status === 200) {
        console.log(response.data);
        setRows(response.data);
      }
    } catch (err) {
      console.log(err)
      if (err.response) {
        if (err.response.status === 400) {
          toast.info("No Inspections Found.");
          return
        }
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  }

  //Inspect a vehicle component
  const renderTableContent = () => {
    if (activeTab === "inspect") {
      if (view === "inspect") {
        return (
          <div className="inspect-form lg:ml-52 px-4 lg:px-0">
            <h3 className="text-2xl font-bold text-[#0b0d6a] mb-6">
              Inspect vehicle
            </h3>
            <div className="flex flex-wrap space-y-4 lg:space-y-0 lg:space-x-8 mb-6 mx-0">
              <div>
                <label htmlFor="vehicle-number" className="mb-3  font-bold">
                  Vehicle Number
                </label>
                <br />
                <input
                  className="h-12 w-72 border-2 rounded-xl mt-3 px-4"
                  type="text"
                  id="vehicle-number"
                  value={vehicleNumber}
                  onChange={handleInputChange}
                  disabled={vehicleDetails.length !== 0}
                  maxLength={10}

                /> <br />
                <label htmlFor="mobile-number" className="mb-3  font-bold">
                  Mobile Number(+91)
                </label>
                <br />
                <input
                  className="h-12 w-72 border-2 rounded-xl mt-3 px-4"
                  type="text"
                  id="mobile-number"
                  value={mobile_insp}
                  onChange={(e) => setMobile_insp(e.target.value)}
                  disabled={vehicleDetails.length !== 0}
                  maxLength={10}
                  placeholder="Enter vehicle number(+91)"
                />
              </div>
              {vehicleDetails.length !== 0 && (
                <>
                  <div>
                    <label className="font-bold">Register Name</label>
                    <br />
                    <input
                      className="h-10 border-2 rounded-3xl mt-3 px-4"
                      type="text"
                      value={vehicleDetails.owner_name}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="font-bold">Insurance Status</label>
                    <br />
                    <input
                      className="h-10 border-2 rounded-3xl mt-3 px-4"
                      type="text"
                      value={vehicleDetails.insurance_status}
                      readOnly
                    />
                  </div>
                </>
              )}
            </div>
            <div className=" flex justify-end w-72 mb-5">
              {vehicleDetails.length === 0 && (
                <button
                  className="border rounded-lg bg-[#00aaf8] p-2 text-sm text-white"
                  onClick={fetchDetailsforInspection}
                >
                  Get details
                </button>
              )}
            </div>

            {vehicleDetails.length !== 0 && (
              <div>
                <div className="flex flex-wrap gap-x-48 lg:gap-x-16 mt-12 gap-y-4 mb-6 mx-8">
                  <label>
                    <input
                      className="h-8 w-8 "
                      type="checkbox"
                      name="drivingLicense"
                      checked={vehicleDetails.drivingLicense}
                      onChange={handleCheckboxChange}
                    />
                    <div className="-mt-8 ml-9 font-semibold ">
                      Driving License
                    </div>
                  </label>
                  <label>
                    <input
                      className="h-8 w-8"
                      type="checkbox"
                      name="pollution"
                      checked={vehicleDetails.pollution}
                      onChange={handleCheckboxChange}
                    />
                    <div className="-mt-8 ml-9 font-semibold ">Pollution</div>
                  </label>
                  <label>
                    <input
                      className="h-8 w-8"
                      type="checkbox"
                      name="rcBook"
                      checked={vehicleDetails.rcBook}
                      onChange={handleCheckboxChange}
                    />
                    <div className="-mt-8 ml-9 font-semibold ">RC Book</div>
                  </label>
                </div>
                <div style={{ display: "grid", placeItems: "center" }}>
                  <button
                    className="bg-blue-400 mb-12 px-6 py-2 rounded-xl text-white font-bold border-black border"
                    onClick={handleInspectionSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      } else if (view === "history") {
        return (
          <div className="off-his">
            <h1 className="off-his-title">Inspect Vehicle History</h1>
            <div className="off-his-table">
              <table className="off-his-table-main">
                <thead className="off-his-table-head">
                  <tr>
                    <th>Sno.</th>
                    <th>Vehicle Number</th>
                    <th>Driving License</th>
                    <th>Pollution</th>
                    <th>RC Book</th>
                    <th>Status</th>
                    <th>Challan</th>
                  </tr>
                </thead>
                <tbody className="off-his-table-body">
                  {rows.inspection_history.map((row, index) => (
                    <tr key={row.id} className="off-his-table-body-row">
                      <td>{index + 1}</td>
                      <td>{row.vehicle_no}</td>
                      {row.documents && (<>
                      <td>{row.documents[0] ? "Yes" : "No"}</td>
                      <td>{row.documents[1] ? "Yes" : "No"}</td>
                      <td>{row.documents[2] ? "Yes" : "No"}</td>
                      <td>{row.insurance_status || "Invalid"}</td></>
                    )
                      }
                      {!row.documents && (<>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td></>
                    )
                      }
                      <td>{row.challan}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {Object.keys(validationMessages).length > 0 && (
                <div className="validation-messages">
                  {Object.entries(validationMessages).map(([key, message]) => (
                    <div key={key} className="validation-message">
                      {message}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      }
    }

    if (activeTab === "complaint") {
      return (
        <div className="off-com-main mx-2 lg:mx-24">
          <h1>File a Complaint</h1>
          <h2>New Report</h2>
          <div className="off-com">
            <div className="left-com">
              <div className="left-com-left">
                <div className="com-label">
                  <label htmlFor="vecnum">Vehicle Number</label>
                  <br />
                  <input
                    type="text"
                    className="com-input"
                    value={complaintVehicleNumber}
                    onChange={(e) => setcomplaintVehicleNumber(e.target.value)}
                  />
                  {errors.complaintVehicleNumber && (
                    <p className="text-red-500">
                      {errors.complaintVehicleNumber}
                    </p>
                  )}
                </div>
                <div className="com-label">
                  <label htmlFor="select">Select Violation Type</label>
                  <br />
                  <select
                    name="select"
                    id="select"
                    className="com-input"
                    value={violationType}
                    onChange={(e) => setViolationType(e.target.value)}
                  >
                    <option value="">Select</option>
                    {violationList.map((vio, index) => (
                      <option key={index} value={vio[0]}>{vio[0]}</option>
                    ))}

                  </select>
                  {errors.violationType && (
                    <p className="text-red-500">{errors.violationType}</p>
                  )}
                </div>
              </div>
              <div className="left-com-right">
                <div className="com-label">
                  <label htmlFor="loc">Select Your Location</label>
                  <br />
                  <textarea
                    name="loc"
                    id="loc"
                    rows={2}
                    cols={4}
                    className="com-input"
                    value={location}
                    disabled
                  />
                  {errors.location && (
                    <p className="text-red-500">{errors.location}</p>
                  )}
                </div>
                <div className="com-btn">
                  <label className="com-sel-btn">
                    Select image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleComplaintImageChange}
                      className="hidden"
                    />
                  </label>
                  <button
                    className="com-sub-btn"
                    onClick={handleComplaintSubmit}
                  >
                    Submit
                  </button>
                  {errors.selectedImage && (
                    <p className="text-red-500">{errors.selectedImage}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="right-com">
              <div
                id="violation-image"
                className="mr-0 w-[300px] lg:mr-0 lg:w-[475px] md:w-64 rounded-2xl shadow-xl bg-gray-100 transition duration-300 hover:bg-gray-200 hover:shadow-2xl"
              >
                <div className="flex justify-between px-6 py-3">
                  <h3 className="text-bold font-bold">Image</h3>
                  <h3
                    className="text-semibold text-[#8B68F7] cursor-pointer"
                    onClick={handleRemoveImage}
                  >
                    Remove
                  </h3>
                </div>
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="mt-2 rounded-3xl p-2 "
                  />
                ) : (
                  <img src={imageIcon} alt="Placeholder" className="mt-4 p-4" />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }



    if (activeTab === "rewards") {
      return (
        <>
          <div className="mx-2 lg:mx-28">
            <section className="mb-8">
              <div className="flex flex-wrap justify-between items-center">
                <div className="flex flex-wrap items-center lg:space-x-40 space-y-6 mt-6">
                  <h2 className="text-4xl ml-6 text-[#10195c] font-bold">
                    Track Record
                  </h2>
                  <div className="border-2 border-[#868686] p-4 rounded-lg text-center w-full lg:w-auto">
                    <p className="font-semibold">Total No.Of Inspections</p>
                    <p className="text-xl font-bold">{rows.inspection_history.length}</p>
                  </div>
                  <div className="border-2 border-[#868686] p-4 rounded-lg text-center w-full lg:w-auto">
                    <p className="font-semibold">Total Challan Amount</p>
                    <p className="text-xl font-bold">{rows.total_challans}</p>
                  </div>
                  <Link to="/Officer/dashboard/month">
                    <button className="mt-4 md:mt-0 p-2 bg-[#0d27bb] border-0 text-white rounded-xl w-full lg:w-auto">
                      Monthly Track
                    </button>
                  </Link>
                </div>
              </div>
            </section>

            <section>
              <div className=" lg:p-4 rounded-lg mb-6">
                <div className="flex justify-start space-x-28 lg:space-x-12 items-center mb-4">
                  {/* <div>
                    <select
                      className="font-bold text-lg bg-transparent w-full outline-none"
                      value={selectedDay}
                      onChange={handleDayChange}
                    >
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="2024-07-13">13/07/2024</option>
                      <option value="2024-07-12">12/07/2024</option>
                      <option value="2024-07-11">11/07/2024</option>
                      
                    </select>
                  </div>*/} 
                  <div>
                    <p className="font-bold text-lg">
                      Inspections: {rows.inspection_history.length}
                    </p>
                  </div>
                </div>

                <div className="overflow-x-auto border-2 rounded-xl">
                  <table className="w-full border-collapse text-sm lg:text-base">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="p-2 border-0">S.NO</th>
                        <th className="p-2 border-0">Vehicle Number</th>
                        <th className="p-2 border-0">Driving License</th>
                        <th className="p-2 border-0">Pollution</th>
                        <th className="p-2 border-0">RC Book</th>
                        <th className="p-2 border-0">Status</th>
                        <th className="p-2 border-0">Challan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.inspection_history.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="p-2 border-0">{index + 1}</td>
                          <td className="p-2 border-0">{item.vehicle_no}</td>
                          {item.documents && (<>
                          <td className="p-2 border-0">
                            {item.documents[0] ? "Yes" : "No"}
                          </td>
                          <td className="p-2 border-0">
                            {item.documents[1] ? "Yes" : "No"}
                          </td>
                          <td className="p-2 border-0">
                            {item.documents[2] ? "Yes" : "No"}
                          </td>
                          <td className="p-2 border-0">
                            {item.insurance_status ? "Yes" : "No"}
                          </td>
                          </>)}
                          {!item.documents && (<>
                          <td className="p-2 border-0">
                            {"-"}
                          </td>
                          <td className="p-2 border-0">
                            {"-"}
                          </td>
                          <td className="p-2 border-0">
                            {"-"}
                          </td>
                          <td className="p-2 border-0">
                            {"-"}
                          </td>
                          </>)}
                          <td className="p-2 border-0">{item.challan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>

        </>
      );
    }
  };

  return (
    <>
      <Navbar />

      <div className="p- mt-[15%] lg:mt-[5%]">
        <a href="/logout">
          <h6
            className="text-2xl font-bold lg:mb-10 text-indigo-950 lg:ml-24"
            style={{
              fontSize: "25px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Logout
          </h6>
        </a>
        <h1
          className="text-2xl font-bold lg:mb-10 text-indigo-950 lg:ml-24"
          style={{
            fontSize: "35px",
            fontFamily: "Poppins",
            fontWeight: "bold",
          }}
        >
          Officer Home
        </h1>
        <div className="mb-4 mt-6 p-2  justify-center flex flex-wrap  lg:space-x-9">
          <button
            className={`transition  duration-500 hover:-translate-y-1 hover:scale-105 font-bold px-4 py-2 mb-6 w-full text-xl border-slate-200  rounded-xl border-2  lg:w-96 h-32 ${activeTab === "inspect"
                ? "bg-[#00ADF8] text-white"
                : "bg-white-200 text-black"
              }`}
            onClick={() => handleTabClick("inspect")}
          >
            Inspect a vehicle
          </button>
          <button
            className={`transition  duration-500 hover:-translate-y-1 hover:scale-105 font-bold px-4 py-2 mb-6 text-xl border-slate-200 border-2 rounded-xl w-full  lg:w-96 h-32 ${activeTab === "complaint"
                ? "bg-[#00ADF8] text-white"
                : "bg-white-200 text-black "
              }`}
            onClick={() => handleTabClick("complaint")}
          >
            File a Complaint
          </button>
          <button
            className={`transition  duration-500 hover:-translate-y-1 hover:scale-105 font-bold px-4 py-2 text-xl border-slate-200 border-2  rounded-xl w-full lg:w-96 h-32 ${activeTab === "rewards"
                ? "bg-[#00ADF8] text-white"
                : "bg-white-200 text-black"
              }`}
            onClick={() => handleTabClick("rewards")}
          >
            Track Record
          </button>
        </div>
        <br /> <br />
        {activeTab === "inspect" && (
          <div className="mb-8 flex space-x-24 -mt-10">
            <button
              className={` font-semibold w-28 text-[17px] text-center ml-3 lg:ml-52 border-none  px-3 py-1.5   t align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer ${view === "inspect"
                  ? "bg-[#0A2BD9] text-white"
                  : "bg-gray-200 text-black"
                }`}
              onClick={() => handleViewClick("inspect")}
            >
              Inspect
            </button>
            <button
              className={` font-semibold  w-28  border-none text-center px-3 py-1.5   text-[17px] align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer  ${view === "history"
                  ? "bg-[#0A2BD9]  text-white"
                  : "bg-gray-200 text-black"
                }`}
              onClick={() => handleViewClick("history")}
            >
              History
            </button>
          </div>
        )}
        <div className="overflow-x-auto">{renderTableContent()}</div>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default OfficerHome;
