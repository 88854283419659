import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import VehicleCard from "./VehicleCard";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function YourVehicles() {
  const [vehicles, setvehicles] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const getVehicles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/common/get-vehicles`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        setvehicles(response.data);
        return;
      }
    } catch (err) {
      if (err.request.status === 404) {
        toast.info("No vehicles found");
        return;
      }
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Session Expired. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  };
  useEffect(() => {
    getVehicles();
  }, [navigate]);
  return (
    <>
      <Navbar />
      <Sidebar />

      <main className="mt-12 mb-12 ml-20 md:ml-20 lg:ml-72 xl:ml-72 lg:mt-[5%]">
        <div className="flex justify-between mt-12 mb-6">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
            Your Vehicles
          </h1>
          <Link to="/dashboard/AddVehicle">
            <button className="bg-black lg:mt-3 hover:bg-blue-700 mr-6 text-white font-semibold h-9 px-3 rounded-full">
              ADD <span className="ml-3 text-xl lg:ml-6">+</span>
            </button>
          </Link>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-12">
          {vehicles.map((vehicle, index) => (
            <div key={index} className="ml-2 lg:ml-14">
              <VehicleCard vehicle={vehicle} />
            </div>
          ))}
        </div>
      </main>
      <ToastContainer position="top-right" />
    </>
  );
}

export default YourVehicles;
