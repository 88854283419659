import React, { useEffect, useState } from 'react';
import OtpInput from './OtpInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Login.css';
import LoginNav from './LoginNav';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

function Login() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryCodes, setCountryCodes] = useState([]);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState("");
    const [editPhoneNumber, setEditPhoneNumber] = useState(false); // State to manage edit phone number
    const [resendTimeout, setResendTimeout] = useState(0); // State to manage resend OTP timeout
    const navigate = useNavigate();

    useEffect(() => {
        fetchCountryCodes();
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                if (!showOtpInput) {
                    handlePhoneSubmit(event);
                } else {
                    handleLogin();
                }
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [showOtpInput, phoneNumber, otp]);

    // Fetch country codes from API
    const fetchCountryCodes = async () => {
        try {
            console.log(process.env.REACT_APP_BACKEND_URL)
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/common/get-options?doctype=countrycodes');
            if (!response) {
                toast.error("Unable to connect. Please try again later.");
            }
            setCountryCodes(response.data);
            setCountryCode(response.data[97].dial_code); // Set the first country code as default
        } catch (error) {
            toast.error("Failed to load country codes");
        }
    };

    // Handle phone number input change
    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    };

    // Handle country code change
    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };

    // Handle phone number submit (Send OTP)
    const handlePhoneSubmit = async (event) => {
        event.preventDefault();

        const fullPhoneNumber = countryCode + phoneNumber;
        const regex = /[^0-9]/g;
        if (phoneNumber.length < 10 || regex.test(phoneNumber)) {
            toast.error("❗ Oops! That doesn't look like a valid number. Try again. 🔢");
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/auth/verify-mobile', { "mobile_number": fullPhoneNumber });
            if(!response){
                toast.error("Unable to connect. Please try again later.");
            }
            if (response.status === 200) {
                setShowOtpInput(true); // Show OTP input field
                setResendTimeout(60); // Set initial countdown for resend OTP
                startResendTimeout(); // Start countdown
            }
        } catch (error) {
            toast.error("🚨 Oops! Something went wrong on our end. 🚨");
        }
    };

    // Handle OTP change
    const handleOtpChange = (otp) => {
        setOtp(otp);
    };

    // Handle login button click (Verify OTP)
    const handleLogin = async () => {
        const fullPhoneNumber = countryCode + phoneNumber;
        if (otp.length !== 6) {
            toast.error("🔐 Please Enter the Complete OTP 🔐");
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/auth/verify-otp', { "mobile_number": fullPhoneNumber, "otp": otp });
            if(!response){
                toast.error("Unable to connect. Please try again later.");
            }
            if (response.status === 200) {
                if (response.data.exists === false) {
                    navigate('/signup', { state: { phoneNumber: fullPhoneNumber } });
                } else if (response.data.access_token) {
                    localStorage.setItem("token", response.data.access_token);
                    navigate('/');
                }
            }
        } catch (err) {
            if(err.response.status === 403){
                toast.error("Unable to fetch details. Please Login Again.");
                navigate('/logout');
              }
            if (err.response.status === 400) {
                toast.error("😟 Oh no! The OTP you entered doesn't match. Please try again. 🔄");
                return;
            } else if (err.response.status === 500) {
                toast.error("🚨 Oops! Something went wrong on our end. 🚨");
                return;
            }
            toast.error("🚨 Oops! Something went wrong on our end. 🚨");
            return
        }
    };

    // Handle edit phone number link click
    const handleEditPhoneNumber = () => {
        setShowOtpInput(false); // Hide OTP input field
        setEditPhoneNumber(true); // Enable editing phone number
    };

    // Start countdown for resend OTP
    const startResendTimeout = () => {
        const timer = setInterval(() => {
            setResendTimeout((prevTime) => prevTime - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
        }, 60000); // 60 seconds
    };

    // Handle resend OTP button click
    const handleResendOTP = async () => {
        const fullPhoneNumber = countryCode + phoneNumber;

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/auth/verify-mobile', { "mobile_number": fullPhoneNumber });
            if(!response){
                toast.error("Unable to connect. Please try again later.");
            }
            if (response.status === 200) {
                setResendTimeout(60); // Reset countdown timer
                startResendTimeout(); // Start countdown again
                toast.success("🔁 OTP Resent Successfully! 🔁");
            }
        } catch (error) {
            toast.error("🚨 Failed to resend OTP. Please try again. 🚨");
        }
    };

    return (
        <>
            <LoginNav />
            <div className='main'>
                <div className="form1"></div>
                <div className='form'>
                    <h3 className='lo'>Login :</h3>
                    {!showOtpInput ? (
                        <form onSubmit={handlePhoneSubmit} className="form-block">
                            <label htmlFor="country-code" className='text-1xl'>Country Code :</label><br />
                            <select name="country-code" id="country-code" value={countryCode} onChange={handleCountryCodeChange} className="input-field" required>
                                {countryCodes.map((code) => (
                                    <option key={code.code} value={code.dial_code}>{code.name} ({code.dial_code})</option>
                                ))}
                            </select>
                            <label htmlFor="phone" className='text-1xl'>Phone Number :</label><br />
                            <input type="text" name="phone" id="phone" value={phoneNumber} 
                            maxLength={10}
                            onChange={handlePhoneNumber} className="input-field ls" placeholder='Enter your Phone Number' required disabled={editPhoneNumber} />
                            {!editPhoneNumber && (
                                <button type="submit" className='btn'>Send OTP</button>
                            )}
                            {editPhoneNumber && (
                                <a href="/" onClick={() => setEditPhoneNumber(false)}>Cancel Edit</a>
                            )}
                        </form>
                    ) : (
                        <div className='otp'>
                            <label htmlFor="phone">Phone Number :</label><br />
                            <input type="text" name="phone" id="phone" value={phoneNumber} onChange={handlePhoneNumber} className="input-field input2 ls" placeholder='Enter your Phone Number' required disabled />
                            <a href="/" onClick={handleEditPhoneNumber} className='resend'><EditIcon /> Edit</a>
                            <br />
                            <label htmlFor="otp">Enter OTP :</label><br />
                            <OtpInput length={6} onOtpChange={handleOtpChange} />
                            <button type="button" className='btn btn-lg' onClick={handleLogin}>Login</button>
                            {resendTimeout === 0 ? (
                                <button type="button" className='resend' onClick={handleResendOTP}>Resend OTP</button>
                            ) : (
                                <p><span className="resend">Resend OTP</span> <br /> in {resendTimeout} seconds</p>
                            )}
                        </div>
                    )}
                </div>
                <ToastContainer position='top-right' />
            </div>
        </>
    );
}

export default Login;
