import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Remove the item from localStorage
        localStorage.removeItem("token");
        localStorage.removeItem('profile_url')
        localStorage.removeItem('role');
        localStorage.removeItem('mobile_number');
        localStorage.removeItem('email');
        localStorage.removeItem('name');

        // Redirect to the login page
        navigate('/login');
    }, [navigate]);

    return null;
}

export default Logout;
