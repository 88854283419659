import React from 'react'
import '../css/Loader.css'

const Loader = () => {
  return (
    <>
        <div className="load">
        <div className="three-body">
<div className="three-body__dot"></div>
<div className="three-body__dot"></div>
<div className="three-body__dot"></div>
</div>
        </div>
    </>
  )
}

export default Loader