import { useEffect, useRef } from "react";
import * as d3 from "d3";
import geoData from "../../../../utils/output.json"; // Path to your GeoJSON file
import challanData from "../../../../utils/districtChallanData.json";
// import "../index.css";

const TelanganaDistrict = () => {
  const svgRef = useRef(null);
  const tooltipRef = useRef(null); // Create a ref for the tooltip

  useEffect(() => {
    const getChallanData = (DISTRICT_N) => {
      return challanData.find(
        (d) => d.district.toLowerCase() === DISTRICT_N.toLowerCase()
      );
    };

    // Function to render the map
    const renderMap = () => {
      const svgElement = svgRef.current;
      const { width, height } = svgElement.getBoundingClientRect();

      // Clear previous SVG content
      d3.select(svgElement).selectAll("*").remove();

      // Create SVG element
      const svg = d3
        .select(svgElement)
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("preserveAspectRatio", "xMidYMid meet");

      // Define a projection
      const projection = d3
        .geoIdentity()
        .reflectY(true) // Reflect Y axis to match typical map orientation
        .fitSize([width, height], geoData); // Fit the projection to SVG size and data

      // Create a path generator
      const path = d3.geoPath().projection(projection);

      // Create a color scale
      const colorScale = d3.scaleOrdinal(d3.schemeTableau10);

      // Draw districts with tooltips
      svg
        .selectAll("path")
        .data(geoData.features)
        .enter()
        .append("path")
        .attr("d", path)
        .attr("stroke", "#000")
        .attr("stroke-width", 0.5)
        .attr("fill", (d, i) => colorScale(i)) // Assign different color for each district
        .on("mouseover", function (event, d) {
          d3.select(this).classed("highlight", true);
          const data = getChallanData(d.properties.DISTRICT_N);

          if (data) {
            tooltip.style("visibility", "visible").html(`
              <table class="hidden md:block  z-1000">
                <tbody class= ">
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-gray-700">District</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left ">${data.district}</td>
                  </tr>
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-nowrap text-gray-700">Total Challans Created</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left">${data.total_challans_created}</td>
                  </tr>
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-gray-700">Total Challans Received</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left">${data.total_challans_received}</td>
                  </tr>
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-gray-700">Total Challans Pending</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left">${data.total_challans_pending}</td>
                  </tr>
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-gray-700">Top Challan Created Officer</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left">${data.top_challan_created_officer_name}</td>
                  </tr>
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-gray-700">Top Challan Paid Citizen</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left">${data.top_challan_paid_citizen}</td>
                  </tr>
                  <tr class="">
                    <td class="px-2 py-1 text-xs font-semibold text-left text-gray-700">Top Challan Created Vehicle</td>
                    <td class="px-2 py-1 text-xs text-gray-500 text-left">${data.top_challan_created_vehicle_name}</td>
                  </tr>
                </tbody>
              </table>
            `);
          } else {
            tooltip.style("visibility", "visible")
              .html(`<div class='hidden md:block'><strong>District:</strong> ${d.properties.DISTRICT_N}<br>
                     <strong>State:</strong> ${d.properties.STATE}<br></div>`);
          }
        })
        .on("mousemove", function (event) {
          tooltip
            .style("top", event.pageY - 10 + "px")
            .style("left", event.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          d3.select(this).classed("highlight", false);
          tooltip.style("visibility", "hidden");
        });

      // Create tooltip element
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background-color", "#f9f9f9")
        .style("border", "1px solid #ccc")
        .style("padding", "10px")
        .style("border-radius", "5px")
        .style("box-shadow", "0 0 10px rgba(0, 0, 0, 0.1)")
        .style("font-size", "14px")
        .style("text-align", "left")
        .style("pointer-events", "none");
    };

    // Initial render
    renderMap();

    // Add resize event listener
    window.addEventListener("resize", renderMap);

    // Clean up on unmount
    return () => {
      window.removeEventListener("resize", renderMap);
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <svg ref={svgRef}></svg>
      <div
        ref={tooltipRef}
        id="tooltip"
        className="tooltip"
        style={{
          position: "absolute",
          background: "#f4f4f4",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          pointerEvents: "none",
          visibility: "hidden",
          opacity: 0,
          transition: "opacity 0.3s",
        }}
      ></div>
    </div>
  );
};

export default TelanganaDistrict;
