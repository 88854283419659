import React, { useEffect, useState, useRef } from 'react';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import Filters from './Filter';
// import '../grpahs_css/OverallChallan.css';

// Sample data
const sampleData = [
  { district: 'Khammam', date: '2024-04-17', amount: 20000, count: 50 },
  { district: 'Bhadradri Kothagudem', date: '2024-04-17', amount: 18000, count: 40 },
  { district: 'Hyd', date: '2024-04-18', amount: 20000, count: 20 },
  // Add more sample data as needed
];

// Telangana districts
const districtOptions = [
  { value: 'Adilabad', label: 'Adilabad' },
  { value: 'Bhadradri Kothagudem', label: 'Bhadradri Kothagudem' },
  { value: 'Hyderabad', label: 'Hyderabad' },
  // Add more districts as needed
];

const OverallChallan = () => {
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const chartRef = useRef(null);

  const handleDistrictChange = (selectedOptions) => {
    setSelectedDistricts(selectedOptions || []);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredData = sampleData.filter((item) => {
    const districtMatch = selectedDistricts.length === 0 || selectedDistricts.some(option => option.value === item.district);
    const dateMatch = !selectedDate || item.date === selectedDate.toISOString().split('T')[0];
    return districtMatch && dateMatch;
  });

  const option = {
    legend: {},
    tooltip: {},
    dataset: {
      dimensions: ['district', 'Collected Challans amount', 'Total No. of Challans'],
      source: filteredData.map(item => ({
        district: item.district,
        'Collected Challans amount': item.amount,
        'Total No. of Challans': item.count
      }))
    },
    xAxis: { type: 'category' },
    yAxis: [
      {
        type: 'value',
        name: 'Collected Challans amount',
        position: 'left'
      },
      {
        type: 'value',
        name: 'Total No. of Challans',
        position: 'right'
      }
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Collected Challans amount',
        itemStyle: {
          color: '#b8d7ff' // Set the color for the first bar series
        }
      },
      {
        type: 'bar',
        yAxisIndex: 1,
        name: 'Total No. of Challans',
        itemStyle: {
          color: '#7bb4fc' // Set the color for the second bar series
        }
      }
    ]
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="overall-main">
        <div className="overall-heading">
        </div>
        <Filters
          title="Overall Challan"
          districtOptions={districtOptions}
          selectedDistricts={selectedDistricts}
          handleDistrictChange={handleDistrictChange}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
        />
        <div className="graph">
          <ReactECharts
            ref={chartRef}
            option={option}
            style={{ width: '100%', height: '400px' }}
          />
        </div>
      </div>
    </>
  );
};

export default OverallChallan;
