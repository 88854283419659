import React from 'react';
import carImage from '../../assets/car.png'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const VehicleCard = ({ vehicle }) => {
  const navigate = useNavigate();
  async function removeVehicle(vehicle_number) {
    try{
      console.log(localStorage.getItem('token'))
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/users/remove-vehicle?vehicle_number=${vehicle_number}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('token'),
      }
    })
    if (!response) {
      toast.error("No response from server. Please try again later.")
      return;
    }
    if (response.status === 200) {
      toast.success('Vehicle removed successfully');
      window.location.reload();
      return;
    }
  }catch(err){
    if(err.response.status === 404){
      toast.error("Vehicle not found. Please try again later.");
      return;
    }
    if (err.response) {
      if (err.response.status === 401) {
        toast.error("Session Expired. Please Login Again.");
        navigate('/logout');
      }
      if(err.response.status === 403){
        toast.error("Unable to fetch details. Please Login Again.");
        navigate('/logout');
      }
    }
    if (err.request) {
      toast.error("Unable to remove vehicle. Please try again later.");
    }
    
  }
  }
  return (
    <div className="w-72 lg:w-auto  p-6 rounded-2xl bg-gray-800 text-white font-sans text-center relative">
      <button className="absolute top-2 right-4 text-blue-400 hover:underline focus:outline-none"
      onClick={() => removeVehicle(vehicle.vehicle_number)}
      >
        Remove
      </button>
      <div className="flex items-center justify-between mb-4">
        <div className="">
          <img src={carImage} alt={vehicle.maker_name} className="h-28 w-28" />
        </div>
        <h2 className="text-xl">{vehicle.maker_name}</h2>
      </div>
      <div className="border-t border-gray-600 mt-2 pt-2">
       

        <table className="w-full border-0">
          
          <tbody>
            <tr>
              <td className="text-left text-nowrap">Vehicle number </td>
              <td className="text-left"> : </td>
              <td className="text-left pl-4">{vehicle.vehicle_number}</td>
            </tr>
            <tr>
              <td className="text-left text-nowrap">Chassis number</td>
              <td className="text-left"> : </td>
              <td className="text-left pl-4">{vehicle.chassis_number}</td>
            </tr>
            <tr>
              <td className="text-left text-nowrap"> Owner name </td>
              <td className="text-left"> : </td>
              <td className="text-left pl-4">{vehicle.owner_name}</td>
            </tr>
            <tr>
              <td className=" text-nowrap text-left "> Challans</td>
              <td className="text-left"> : </td>
              <td className="text-left pl-4">{vehicle.challans>0?vehicle.challans:0}</td>
            </tr>
          </tbody>  
        </table>
      </div>
      <ToastContainer position='top-right' />
    </div>
  );
};

export default VehicleCard;