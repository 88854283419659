import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverallChallan from "./Graphs/OverallChallan";
import PieChart from "./Graphs/ChallanCollectionBlockpie";
import ChallanCollectionBlock from "./Graphs/ChallanCollectionBlock";
import TelanganaDistrict from "./Graphs/TelanganaDistrict";

const CommissionerHome = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate fetching data from an API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/api/commissioner/get-chome-data",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (!response) {
          toast.error("Error fetching data");
          return;
        }
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            toast.error("Unable to fetch details. Please Login Again.");
            navigate("/logout");
          }
          if (err.response.status === 403) {
            toast.error("Unable to fetch details. Please Login Again.");
            navigate("/logout");
          }
        }
        if (err.request) {
          toast.error("Unable to fetch details. Please try again later.");
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="mt-12 lg:mt-20 p-2 lg:p-4 ">
        <div className="flex justify-between">
          <h2 className="text-3xl mb-10 left-0 font-bold text-[#0a1365]">
            Commissioner Home
          </h2>
          <a href="/logout">
            <h6
              className="text-xs font-bold lg:mb-10 text-indigo-950 lg:ml-24"
              style={{
                fontSize: " ",
                fontFamily: "Poppins",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Logout
            </h6>
          </a>
        </div>

        <section className="w-full  flex flex-row gap-8">
          <div className="flex flex-wrap gap-8 flex-row justify-center lg:w-[1000px]">
            <Link to="/commissioner/dashboard/topperformers">
              <button className="transition  duration-500 hover:-translate-y-1 hover:scale-105 font-bold text-xl h-20 text-white px-4 py-2 rounded bg-[#445df2] mb-6 w-full lg:w-72 shadow">
                Top Performers
              </button>
            </Link>
            <Link to="/commissioner/dashboard/wavedchallans">
              <button className="transition  duration-500 hover:-translate-y-1 hover:scale-105 font-bold text-xl h-20 text-white px-4 py-2 rounded bg-[#445df2] lg:w-72 mb-6 w-full shadow">
                Waive challan
              </button>
            </Link>
          </div>
        </section>
        <main className="grid sm:grid-cols-1 lg:mt-12  lg:grid-cols-2 ">
          <div className="lg:w-[1400px] lg:-mx-[22rem] ">
            <TelanganaDistrict />
          </div>

          <div className="flex flex-col lg:flex-row">
            <section className="w-full   flex flex-wrap lg:gap-8 lg:mx-0">
              <section className="mb-8 ">
                <h3 className="text-2xl lg:text-3xl mb-6 lg:mb-12 font-bold text-[#0a1365]">
                  Total challans
                </h3>

                <div>
                  <OverallChallan />
                </div>
                <div className="flex justify-center lg:-ml-28">
                  <div className="grid grid-cols-5 mt-4 lg:w-[800px] md:grid-cols-5">
                    <div className="flex flex-wrap flex-col  justify-center items-center">
                      <div className="text-3xl font-bold mb-2 shadow-2xl flex items-center justify-center rounded-lg lg:w-48 w-16 h-16 lg:h-28 px-6">
                        {data.total_challans_by_officers}
                      </div>
                      <div className="text-[#22275d] font-semibold text-[9px] lg:text-sm lg:text-nowrap">
                        Total no. of challans by police
                      </div>
                    </div>
                    <div className="flex flex-wrap lg:ml-9 flex-col justify-center items-center font-bold text-5xl -mt-9">
                      +
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center">
                      <div className="text-3xl font-bold mb-2 shadow-2xl flex items-center justify-center rounded-lg w-16 h-16 lg:w-48 lg:h-28 px-6">
                        {data.total_challans_by_citizens}
                      </div>
                      <div className="text-[#22275d] lg:text-sm text-[9px] lg:text-nowrap font-semibold">
                        Total no. of challans by citizen
                      </div>
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center font-bold text-5xl lg:ml-9 -mt-9">
                      =
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center">
                      <div className="text-3xl font-bold mb-2 shadow-2xl flex items-center justify-center rounded-lg w-16 h-16 lg:w-48 lg:h-28 px-6">
                        {data.total_challans_by_officers +
                          data.total_challans_by_citizens}
                      </div>
                      <div className="text-[#22275d] font-semibold lg:text-sm text-[9px]">
                        Total no. of challans
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="lg:-ml-[32rem]    mb-8 mt-24 ">
                <div>
                  <ChallanCollectionBlock />
                </div>
                <div className="">
                  <div className="grid grid-cols-5 md:grid-cols-5">
                    <div className="flex flex-wrap flex-col justify-center items-center">
                      <div className="text-3xl font-bold mb-2 shadow-2xl flex items-center justify-center rounded-lg w-16 h-16 lg:w-48 lg:h-28 px-6">
                        {data.total_reviewed_citizen_challans}
                      </div>
                      <div className="text-[#22275d] lg:text-nowrap font-semibold lg:text-sm text-[9px]">
                        Citizens challans (reviewed)
                      </div>
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center font-bold text-5xl  lg:ml-9  -mt-9">
                      +
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center">
                      <div className="text-3xl font-bold mb-2 shadow-2xl flex items-center justify-center rounded-lg w-16 h-16 lg:w-48 lg:h-28 px-6">
                        {data.total_unreviewed_citizen_challans}
                      </div>
                      <div className="text-[#22275d] lg:text-sm text-[9px] lg:text-nowrap font-semibold">
                        Citizens challans (Not reviewed)
                      </div>
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center font-bold text-5xl ml-6 lg:ml-9 -mt-9">
                      =
                    </div>
                    <div className="flex flex-wrap flex-col justify-center items-center">
                      <div className="text-3xl font-bold mb-2 shadow-2xl flex items-center justify-center rounded-lg w-16 h-16 lg:w-48 lg:h-28 px-6">
                        {data.total_reviewed_citizen_challans +
                          data.total_unreviewed_citizen_challans}
                      </div>
                      <div className="text-[#22275d] font-semibold lg:text-sm text-[9px]">
                        Total no. of challans by Citizens
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </main>

        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default CommissionerHome;
