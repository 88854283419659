import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import { Link, useNavigate } from "react-router-dom";
import '../../css/Monthly.css';
import '../../css/OfficerInspection.css';
import axios from 'axios';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OfficerMonthly = () => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulating an API call
    const fetchData = async () => {
      const data = [
        { month: 'JAN', inspections: 50, amount: 30000 },
        { month: 'FEB', inspections: 40, amount: 25000 },
        { month: 'MAR', inspections: 60, amount: 35000 },
        { month: 'APR', inspections: 70, amount: 45000 },
        { month: 'MAY', inspections: 80, amount: 50000 }
      ];
      setMonthlyData(data);
    };
    getOfficerRequests();

    // fetchData();
  }, []);
  const monthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
};
  const getOfficerRequests = async () => {
    
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/officer/get-complete-inspection-data", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      })
      if (!response) {
        toast.error("Unable to fetch details. Please try again later.");
        return;
      }
      if (response.status === 200) {
        console.log(response.data.months);
        setMonthlyData(response.data.months.reverse());
      }
    } catch (err) {
      // console.log(err)
      if (err.response) {
        if (err.response.status === 400) {
          // toast.info("No Inspections Found.");
          return
        }
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate('/logout');
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  }

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  if (selectedMonth) {
    return <OfficerInspection dataP={selectedMonth} />;
  }
  function getTotalChallansAmount(data) {
    let totalChallans = 0;
    
    data.daily_data.forEach(day => {
        day.data.inspections.forEach(inspection => {
            totalChallans += inspection.challan;
        });
    });
    
    return totalChallans;
}

  return (
    
    <>
      <Navbar/>
      <div className="month-list">
        <h1>Monthly Track</h1>
        {monthlyData.map((data, index) => (
          
          <button key={index} onClick={() => handleMonthClick(data)}>
            <div className="date">
              <p>Month</p>
              <h6>{monthMap[data.month.split('-')[1]]}</h6>
            </div>
            <div className="off-challans">
              <div className="off-count">
                <p>Total No. of Inspections</p>
                <h5>{data.total_inspections}</h5>
              </div>
              <div className="off-amount">
                <p>Total Challan Amount</p>
                <h5>Rs{getTotalChallansAmount(data)}</h5>
              </div>
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

const findCountByDate = (data, targetDate) => {
  const dailyEntry = data.daily_data.find(entry => entry.date === targetDate);
  console.log(dailyEntry);
  return dailyEntry ? dailyEntry.data.count : null;
};

const findInspectionsByDate = (data, targetDate) => {
  const dailyEntry = data.daily_data.find(entry => entry.date === targetDate);
  return dailyEntry ? dailyEntry.data.inspections : [];
};

const OfficerInspection = ({ dataP }) => {
  console.log(dataP);
  function getTotalChallansAmount(data) {
    let totalChallans = 0;
    
    data.daily_data.forEach(day => {
        day.data.inspections.forEach(inspection => {
            totalChallans += inspection.challan;
        });
    });
    
    return totalChallans;
}
  const [date, setDate] = useState('14/07/2024');
  const dates = ['13/07/2024', '14/07/2024', '15/07/2024'];
  const [inspections, setInspections] = useState([
    { officerId: 2634628, vehicleNumber: 'TS4658AP', drivingLicense: 'Yes', pollution: 'Yes', rcBook: 'Yes', status: 'Yes', challan: 0 },
    { officerId: 2634628, vehicleNumber: 'TS4658AP', drivingLicense: 'Yes', pollution: 'Yes', rcBook: 'No', status: 'No', challan: 500 },
    { officerId: 2634628, vehicleNumber: 'TS4658AP', drivingLicense: 'Yes', pollution: 'Yes', rcBook: 'Yes', status: 'Yes', challan: 0 },
    { officerId: 2634628, vehicleNumber: 'TS4658AP', drivingLicense: 'Yes', pollution: 'No', rcBook: 'No', status: 'No', challan: 1000 },
    { officerId: 2634628, vehicleNumber: 'TS4658AP', drivingLicense: 'Yes', pollution: 'Yes', rcBook: 'Yes', status: 'Yes', challan: 0 }
  ]);

  const totalInspections = dataP.total_inspections;
  // const totalInspections = 0;
  const totalChallanAmount = getTotalChallansAmount(dataP);
  // const totalChallanAmount = 0;


  return (
    <div className="container">
      <Navbar />
      <div className="officer-insp-home">
        <button>
          <div className="date">
            <p>Month</p>
            <h6>{[dataP.month.split('-')[1]]}</h6>
          </div>
          <div className="off-challans">
            <div className="off-count">
              <p>Total No.of Inspections</p>
              <h5>{totalInspections}</h5>
            </div>
            <div className="off-amount">
              <p>Total Challan Amount</p>
              <h5>Rs{totalChallanAmount}</h5>
            </div>
          </div>
        </button>
        <div className="inspection-date">
          <label htmlFor="date-select">Date:</label>
          <select
            id="date-select"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          >
            <option value="">Select Date</option>
            {dataP.daily_data.map((dateOption) => (
              <option key={dateOption.date} value={dateOption.date}>
                {dateOption.date}
              </option>
            ))}
          </select>
          <div className="inspection-count">
            Inspections: {findCountByDate(dataP, date)}
          </div>
        </div>
        <table className="inspection-table">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Vehicle Number</th>
              <th>Driving License</th>
              <th>Pollution</th>
              <th>RC Book</th>
              <th>Status</th>
              <th>Challan</th>
            </tr>
          </thead>
          <tbody>
            {findInspectionsByDate(dataP,date).map((inspection, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{inspection.vehicle_no}</td>
                {inspection.documents && (<>
                      <td>{inspection.documents[0] ? "Yes" : "No"}</td>
                      <td>{inspection.documents[1] ? "Yes" : "No"}</td>
                      <td>{inspection.documents[2] ? "Yes" : "No"}</td>
                      <td>{inspection.insurance_status || "Invalid"}</td></>
                    )
                      }
                      {!inspection.documents && (<>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td></>
                    )
                      }
                <td>{inspection.challan}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default OfficerMonthly;
