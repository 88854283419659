import { useEffect, useState } from "react";
import imageIcon from "../../assets/Image.png";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import { Button } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
// import Loader from "./Loader";
import { RiCloseCircleLine } from "react-icons/ri";

function Violation() {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setimage] = useState(null);
  const [vehiclenumber, setvehiclenumber] = useState("");
  const [violationtype, setviolationtype] = useState("");
  const [violationdate, setviolationdate] = useState(today);
  const [reportHistory, setreportHistory] = useState([]);
  const [violationList, setviolationList] = useState([]);
  const navigate = useNavigate();
  const [address, setAddress] = useState("");


    //Image Modal
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
  
    const openModal = (imageSrc) => {
      setModalImage(imageSrc);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setModalImage("");
    };
  
    const Modal = ({ imageSrc, onClose }) => (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="relative">
          <img
            src={imageSrc}
            alt="Enlarged Violation"
            className="max-w-screen max-h-screen rounded-xl"
          />
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-2 text-white   bg-black bg-opacity-50 px-2 py-1 rounded-lg"
          >
            <RiCloseCircleLine className="text-3xl"/>
          </button>
        </div>
      </div>
    );

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPlaceName(latitude, longitude);
        },
        (error) => {
          handleError(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000, // 10 seconds timeout
          maximumAge: 0, // No cached position
        }
      );
    } else {
      console.error("Geolocation is not available");
    }
  };

  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("An unexpected error occurred.");
        break;
    }
  };

  const getPlaceName = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      if (data.display_name) {
        setAddress(`${data.display_name}`);
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress("Error retrieving address.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setimage(e.target.files[0]);
    }
  };
  const fetchviolations = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/api/common/get-options?doctype=violations"
      );
      if (!response) {
        toast.error("Unable to connect. Please try again later.");
      }
      setviolationList(response.data);
    } catch (error) {
      toast.error("Failed to load violations.");
    }
  };
  const removeImage = () => {
    setSelectedImage(null);
  };

  const handleSubmit = async () => {
    if (!selectedImage) {
      toast.error("Please select an image");
      return;
    }

    if (!vehiclenumber) {
      toast.error("Please enter vehicle number");
      return;
    }

    if (!violationtype) {
      toast.error("Please select violation type");
      return;
    }

    const violationData = {
      vehicle_number: vehiclenumber,
      violation_type: violationtype,
      violation_date: violationdate,
      violation_address: address,
    };
    const formData = new FormData();
    formData.append("violation_data", JSON.stringify(violationData));
    formData.append("violation_img", image);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/api/users/report-violation",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
        return;
      }
      if (response.status === 200) {
        toast.success("Violation Reported Successfully");
        setvehiclenumber("");
        setviolationtype("");
        setSelectedImage(null);
        getViolations();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  };

  function goto_reportHistory() {
    navigate("/dashboard/reportHistory", {
      state: { reportHistory: reportHistory },
    });
  }
  async function getViolations() {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/common/get-violations",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response) {
        toast.error("No response from server. Please try again later.");
      } else {
        if (response.status === 200) {
          setreportHistory(response.data);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
        if (err.response.status === 403) {
          toast.error("Unable to fetch details. Please Login Again.");
          navigate("/logout");
        }
      }
      if (err.request) {
        toast.error("Unable to fetch details. Please try again later.");
      }
    }
  }
  useEffect(() => {
    getViolations();
    fetchviolations();
    getLocation();
  }, []);

  return (
    <>
      <Sidebar />
      <Navbar />
      <main className="mt-12 ml-20 md:ml-20 lg:mt-20 lg:ml-32 xl:ml-72">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
          File a Complaint
        </h1>
        <div className="flex flex-wrap gap-4 sm:gap-6 md:gap-7 mt-8 sm:mt-10 md:mt-12">
          <div className="w-[300px] lg:w-[740px] lg:max-h-[300px] rounded-2xl shadow-xl bg-gray-100 lg:p-5 transition duration-300 hover:bg-gray-200 hover:shadow-2xl">
            <p className="text-lg sm:text-xl ml-4 mt-2 font-semibold mb-3">
              New Complaint
            </p>
            <hr className="mb-6 border-0 h-0.5 bg-gray-400" />
            <div className="flex flex-wrap  gap-x-8">
              <div className="mb-0">
                <div>
                  <label htmlFor="vehicleNumber" className="ml-3 text-lg">
                    Vehicle Number
                  </label>
                  <br />
                  <input
                    type="text"
                    name="vehicleNumber"
                    id="vehicleNumber"
                    value={vehiclenumber}
                    onChange={(e) => {
                      setvehiclenumber(e.target.value);
                    }}
                    placeholder="Enter Vehicle Number"
                    className="ml-3 mt-1 p-2 w-[275px] lg:w-[260px]  bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] mb-6 "
                  />
                </div>
                <div className="mb-6 lg:mb-0">
                  <label htmlFor="violationType" className="ml-3 text-lg">
                    Violation Type
                  </label>
                  <br />
                  <select
                    name="violationType"
                    id="violationType"
                    value={violationtype}
                    onChange={(e) => {
                      setviolationtype(e.target.value);
                    }}
                    className="ml-3 mt-1 p-2 w-[275px]  lg:w-[260px]  bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] "
                  >
                    <option value="">Select Type</option>
                    {violationList.map((vio, index) => (
                      <option key={index} value={vio[0]}>
                        {vio[0]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="pincode" className="ml-3 text-lg">
                  Your Location
                </label>
                <br />
                <textarea
                  type="text"
                  name="pincode"
                  id="pincode"
                  rows={2}
                  cols={4}
                  value={address}
                  // placeholder="Enter"
                  className="ml-3 mt-1 p-2 w-[275px] lg:w-full sm:w-auto overflow-hidden resize-none bg-transparent border border-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-[#000] lg:h-[8vh]"
                  disabled
                />
                <div className="flex gap-12 lg:gap-28 ml-12 mb-3 lg:ml-16 mt-9">
                  <label className="border-none flex px-3 py-1.5 bg-[#8B68F7] text-white text-xs align-middle items-center rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer">
                    Select image *
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </label>
                  <button
                    onClick={handleSubmit}
                    className="border-none lg:w-[83px] font-semibold flex items-center justify-center px-3 py-1.5 bg-[#8B68F7] text-white text-[10px] rounded-xl select-none gap-3 shadow-lg transition duration-600 ease-in-out hover:shadow-xl focus:opacity-85 active:opacity-85 focus:shadow-none active:shadow-none cursor-pointer"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="violation-image"
            className="mr-0 w-[300px] lg:mr-0 lg:w-[447px] md:w-64 rounded-2xl shadow-xl bg-gray-100 transition duration-300 hover:bg-gray-200 hover:shadow-2xl"
          >
            <div className="flex justify-between px-6 py-3">
              <h3 className="text-bold font-bold ">Image <span className="text-red-600">*</span></h3>
              <h3
                className="text-semibold text-[#8B68F7] cursor-pointer"
                onClick={removeImage}
              >
                Remove
              </h3>
            </div>
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Selected"
                className="mt-2 rounded-3xl p-2"
              />
            ) : (
              <img src={imageIcon} alt="Placeholder" className="mt-4 p-4" />
            )}
          </div>
        </div>
        <div className=" rounded-xl mr-4 mt-12 shadow-xl hover:bg-gray-200 bg-gray-100 mb-12 transition duration-300 lg:p-6">
          <div className="flex justify-between pb-4 px-2">
            <h3 className="text-xl lg:text-2xl font-semibold">Reports</h3>
            <h3 className="text-xs text-[#8B68F7] cursor-pointer">
              <Button onClick={goto_reportHistory} className="">
                View More &gt;
              </Button>
            </h3>
          </div>
          {reportHistory.length > 0 ? (
            <div className="overflow-x-auto text-sm">
              <table className="table-auto w-full">
                <thead className="border-t-2 border-b-2 bg-transparent border-gray-500">
                  <tr className="">
                    <th className="px-4 py-2 text-left">VehicleNo</th>
                    <th className="px-4 py-2 text-center">Violation</th>
                    <th className="px-4 py-2 text-center">Date</th>
                    <th className="px-4 py-2 text-center">Image</th>
                  </tr>
                </thead>
                <tbody>
                  {reportHistory.slice(0, 2).map((violation, index) => (
                    <tr className="border-b" key={index}>
                      <td className="px-4 py-2 text-left">
                        {violation.vehicle_number}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {violation.violation_type}
                      </td>
                      <td className="px-4 py-2 text-center text-xs lg:text-sm">
                        {violation.violation_date}
                      </td>
                      <td className="px-4 py-2 flex justify-center">
                        <img
                          src={violation.violation_img_url || imageIcon}
                          alt=""
                          onClick={() => openModal(violation.violation_img_url)}
                          className="max-h-10 rounded-lg cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="h-[300px] flex justify-center text-sm lg:text-base items-center">
              <span className="font-bold text-center  ">oh!!</span>&ensp; You
              have no submitted Violations
            </div>
          )}
        </div>
        {isModalOpen && <Modal imageSrc={modalImage} onClose={closeModal} />}
      </main>
      <ToastContainer position="top-right" />
    </>
  );
}

export default Violation;
